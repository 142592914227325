
/* Month Banner */

  

  
  /* Extra small devices (phones, 320px to 374px) */
  @media (min-width: 320px) and (max-width: 374px) {
  
    .month-container{
      height: 100%;
      font-size: medium;
      padding-bottom: 10%;
    }
    .month-btn{
     margin-top: 10%;
    
    }
    
.month-img{

  display: none;
}
  
  }
  
  /* Small devices (phones, 375px to 479px) */
  @media (min-width: 375px) and (max-width: 479px) {
  
    .month-container{
      height: 100%;
      font-size: medium;
      padding-bottom: 10%;
    }
    .month-btn{
     margin-top: 10%;
    
    }
    
.month-img{

  display: none;
}
  
  }
  
  /* Small to medium devices (phones, 480px to 599px) */
  @media (min-width: 480px) and (max-width: 599px) {
  
    .month-img{
  
      display: block;
    }
    
  
  }
  
  /* Medium devices (tablets, 600px to 767px) */
  @media (min-width: 600px) and (max-width: 767px) {
    .month-img{
  
      display: block;
    }
  
  }
  
  /* Medium to large devices (tablets, 768px to 991px) */
  @media (min-width: 768px) and (max-width: 991px) {
    .month-img{
  
      display: block;
    }
    
  }
  
  /* Large devices (desktops, 992px to 1000px) */
  @media (min-width: 992px) and (max-width: 1000px) {
    .month-img{
  
      display: block;
    }
  
  }
  
  /* Extra large devices (large desktops, 1001px to 1024px) */
  @media (min-width: 1001px) and (max-width: 1024px) {
  
    .month-img{
  
      display: block;
    }
  }
  
  /* Extra extra large devices (larger desktops, 1025px to 1440px) */
  @media (min-width: 1025px) and (max-width: 1280px) {
    .month-img{
  
      display: block;
    }
    .month-container
    {
      padding-left: 7%;
      padding-right: 3%;
    }
  
  }
  @media (min-width: 1281px) and (max-width: 1440px) {
    .month-img{
  
      display: block;
    }
    .month-container
    {
      padding-left: 8%;
      padding-right: 5%;
    }
  
  }

  @media (min-width:1441px) {
   
    .month-img{
  
      display: block;
    }
    .month-container
    {
      padding-left: 5%;
      padding-right: 5%;
    }

  }
  
  /* Ultra wide devices (1442px to 1600px ) */
  @media (min-width:1536px) {
   
    .month-img{
  
      display: block;
    }
    
    .month-container
    {
      padding-left: 8%;
      padding-right: 5%;
    }


  }


  
  @media (min-width:1600px) {
   
    .month-img{
  
      display: block;
      margin-left: 11%;
    }
    .month-text{
      margin-left: 5%;
      font-size: x-large;
    }
    .month-container
    {
   
      justify-content:space-between;
    }
  }