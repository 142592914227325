.productall-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
  }
  .productall-container-filter{
    display: flex;
    flex-direction: row;
  }
  
  .filter-container,.prodctall-main{
    margin-top: 50px;
  }

  .productall-box {
    width: 100%;
    padding: 2%;
    box-sizing: border-box;
  }
  .productall-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 16px;
  }
  
    


/* Add to your CSS file (e.g., App.css) */
.filter-popup {
  position: fixed;
  top: 0;
  left: -13px; /* Position the popup on the left */
  width: 300px; /* Adjust width as needed */
  height: 100%;
  background: white;
  border-right: 1px solid #ddd; /* Border on the right side */
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); /* Shadow to the right */
  transform: translateX(-100%); /* Start off-screen to the left */
  transition: transform 0.3s ease-in-out;
  z-index: 50;
}

.filter-popup.open {
  transform: translateX(0); /* Slide in from the left */
}

.filter-popup .close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  color: #ff0000;
}


    
  
  @media screen and (min-width: 360px) and (max-width: 374px)   {    
    
    .productall-container {
    display: grid;
    grid-template-columns: repeat(2, minmax(150px, 1fr));
    padding-left: 4%;
    padding-right: 3%;
    width: 93%;

  }
  .productall-btn {
    gap: 4%;
    justify-content: center;
    
    margin-right: 7px;
}
  .prodctall-main {
    width: 100%;
  }
  .productall-container-filter {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .productall-container-filter2 {
    display: flex;
    flex-direction: column;
  }
   .productall-full-container{
    justify-content: center;
   }
   .productall-box img{
    width: 100%;
    height: 151px;
   }
}
  
  

  @media(min-width:375px)  and (max-width:424px)
  {    
    
    .productall-container {
    display: grid;
    grid-template-columns: repeat(2, minmax(150px, 1fr));
    padding-left: 4%;
    padding-right: 4%;
    width: 92%;

  }
  .prodctall-main {
    width: 100%;
  }
  .productall-container-filter {
    display: flex;
    flex-direction: column;
    width: auto;
  }
  .productall-container-filter2 {
    display: flex;
    flex-direction: column;
  }
   .productall-full-container{
    justify-content: center;
   }
   .productall-box{
    width: 100%;
    padding: 4%;
  }

  .productall-box img{
    width: 100%;
    height: 151px;
   }
}
  
  @media(min-width:425px)  and (max-width:490px) 
  {    
    
    .productall-container {
    display: grid;
    grid-template-columns: repeat(2, minmax(150px, 1fr));
    padding-left: 4%;
    padding-right: 4%;
    width: 92%;

  }
  .prodctall-main {
    width: 100%;
  }
  .productall-container-filter {
    display: flex;
    flex-direction: column;
    width: auto;
  }
  .productall-container-filter2 {
    display: flex;
    flex-direction: column;
  }
   .productall-full-container{
    justify-content: center;
   }
   .productall-box{
    width: 100%;
    padding: 4%;
  }

   .productall-box img{
    width: 100%;
    height: 173px;
   }
}
  @media(min-width:491px)  and (max-width:526px) 
  {

    .productall-box{
      width: 100%;
      height: auto;
      padding: 7%;
    }
    .productall-container {
      display: grid;
      grid-template-columns: repeat(2, minmax(150px, 1fr));
      padding-left: 4%;
      padding-right: 4%;
      width: 92%;
  
    }

    .prodctall-main {
      width: 100%;
    }
    .productall-container-filter {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .productall-container-filter2 {
      display: flex;
      flex-direction: column;
    }

    .productall-full-container{
      justify-content: center;
     }
     .productall-container {
      grid-template-columns: repeat(2, 1fr);
    }
    .productall-btn-add{
      width: 120px;
      height: 40px;
      font-size: 12px;
      
     }
     .productall-btn-add svg{
      width: 19px;
     }
     .productall-box img{
      width: 100%;
      height: 204px;
     }

  
  }
  

  @media(min-width:527px)  and (max-width:600px) 
  {

    .productall-box{
      width: 100%;
      height: auto;
      padding: 7%;
    }
    .productall-container {
      display: grid;
      grid-template-columns: repeat(2, minmax(150px, 1fr));
      padding-left: 4%;
      padding-right: 4%;
      width: 92%;
  
    }

    .prodctall-main {
      width: 100%;
    }
    .productall-container-filter {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .productall-container-filter2 {
      display: flex;
      flex-direction: column;
    }

    .productall-full-container{
      justify-content: center;
     }
     .productall-container {
      grid-template-columns: repeat(2, 1fr);
    }
    .productall-btn-add{
      width: 120px;
      height: 40px;
      font-size: 12px;
      
     }
     .productall-btn-add svg{
      width: 19px;
     }
     .productall-box img{
      width: 100%;
      height: 204px;
     }

  
  }
  
  
  @media(min-width:601px)  and (max-width:767px) 
  {    
    
    .productall-container {
    display: grid;
    grid-template-columns: repeat(3, minmax(150px, 1fr));
    padding-left: 4%;
    padding-right: 3%;
    width: 93%;

  }
  .productall-btn {
    gap: 4%;
    justify-content: center;
    
    margin-right: 7px;
}
  .prodctall-main {
    width: 100%;
  }
  .productall-container-filter {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .productall-container-filter2 {
    display: flex;
    flex-direction: column;
  }
   .productall-full-container{
    justify-content: center;
   }
   .productall-box img{
    width: 100%;
    height: 181px;
   }
}
  

  @media(min-width:768px)  and (max-width:769px) 
  {

    .productall-box{
      width: 210px;
      padding: 7%;
    }

    .prodctall-main {
      width: 100%;
    }
    .productall-container-filter {
      display: flex;
      flex-direction: column;
      width:100%;
    }
    .productall-container-filter2 {
      display: flex;
      flex-direction: column;
    }

    .productall-full-container{
      justify-content: center;
     }
     .productall-container {
      grid-template-columns: repeat(3, 1fr);
    }
    .productall-box img{
      width: 100%;
      height: 181px;
     }
  
  }
  
  
  @media (max-width:768px){
  
    .filter-container{
      display: none;
    }
  
  
  }
  
  
  
  @media (min-width: 769px) and (max-width: 1024px) {

    .prodctall-main {
      width: 100%;
    }
    .productall-container-filter {
      display: flex;
      flex-direction: row;
      width: 100%;
    }
    .productall-container-filter2 {
      display: flex;
      flex-direction: row;
    }

    .productall-full-container{
      justify-content: center;
      gap: 2%;
      padding: 3%;
    }


    .productall-container {
      grid-template-columns: repeat(3, 1fr);
   
    }
    .prodctall-main{
      width: auto;

    }
    .filter-container
    {

      margin-top: 35px;
  
    }
    .productall-box{
      width:100%;
      padding: 6%;
    }
    .productall-box img{
      width: 100%;
      height: 183px;
     }
  }

  @media (min-width: 1025px) and (max-width: 1200px)
  {

    .prodctall-main {
      width: 100%;
    }
    .productall-container-filter {
      display: flex;
      flex-direction: row;
      width: 100%;
    }
    .productall-container-filter2 {
      display: flex;
      flex-direction: row;
    }

    .productall-full-container{
      justify-content: center;
      gap: 2%;
      padding: 3%;
    }


    .productall-container {
      grid-template-columns: repeat(3, 1fr);
   
    }
    .prodctall-main{
      width: auto;

    }
    .filter-container
    {

      margin-top: 35px;
  
    }
    .productall-box{
      width:100%;
      padding: 6%;
    }
    .productall-box img{
      width: 100%;
      height: 230px;
     }
  }
  @media (min-width: 1201px) and  (max-width: 1280px) 
  {

    .prodctall-main {
      width: 100%;
    }
    .productall-container-filter {
      display: flex;
      flex-direction: row;
      width: 100%;
    }
    .productall-container-filter2 {
      display: flex;
      flex-direction: row;
    }

    .productall-full-container{
      justify-content: center;
      gap: 2%;
      padding: 3%;
    }


    .productall-container {
      grid-template-columns: repeat(4, 1fr);
   
    }
    .prodctall-main{
      width: auto;

    }
    .filter-container
    {

      margin-top: 35px;
  
    }
    .productall-box{
      width: 100%;
      padding: 7%;
    }
    .productall-btn-add{
      width: 122px;
      height: 38px;
      
     }
     .productall-btn-add svg{
      width: 19px;
     }
     .productall-box img{
      margin-bottom: 3%;
      width: 100%;
      height: 181px;
     }
  }

  @media (min-width: 1281px) and  (max-width: 1440px)
  {

    .prodctall-main {
      width: 100%;
    }
    .productall-container-filter {
      display: flex;
      flex-direction: row;
      width: 100%;
    }
    .productall-container-filter2 {
      display: flex;
      flex-direction: row;
    }

    .productall-full-container{
      justify-content: center;
      gap: 2%;
      padding: 3%;
    }


    .productall-container {
      grid-template-columns: repeat(4, 1fr);
   
    }
    .prodctall-main{
      width: auto;

    }
    .filter-container
    {

      margin-top: 35px;
  
    }
    .productall-box{
      width: 100%;
      height: 360px;
      padding: 7%;
    }
    .productall-btn-add{
      width: 122px;
      height: 38px;
      
     }
     .productall-btn-add svg{
      width: 19px;
     }
     .productall-box img{
      margin-bottom: 7%;
      width: 100%;
      height: 213px;
     }
  }

  @media (min-width: 1441px) and  (max-width: 1536px){

    .prodctall-main {
      width: 100%;
    }
    .productall-container-filter {
      display: flex;
      flex-direction: row;
      width: 100%;
    }
    .productall-container-filter2 {
      display: flex;
      flex-direction: row;
    }

    .productall-full-container{
      justify-content: center;
      gap: 2%;
      padding: 3%;
    }


    .productall-container {
      grid-template-columns: repeat(4, 1fr);
   
    }
    .prodctall-main{
      width: auto;

    }
    .filter-container
    {

      margin-top: 35px;
  
    }
    .productall-box{
      width: 100%;
      height: 360px;
      padding: 7%;
    }
    .productall-btn-add{
      width: 122px;
      height: 38px;
      
     }
     .productall-btn-add svg{
      width: 19px;
     }
     .productall-box img{
      margin-bottom: 7%;
      width: 100%;
      height: 232px;
     }
  }

  @media (min-width: 1537px) and  (max-width: 1600px){

    .prodctall-main {
      width: 100%;
    }
    .productall-container-filter {
      display: flex;
      flex-direction: row;
      width: 100%;
    }
    .productall-container-filter2 {
      display: flex;
      flex-direction: row;
    }

    .productall-full-container{
      justify-content: center;
      gap: 2%;
      padding: 3%;
    }


    .productall-container {
      grid-template-columns: repeat(4, 1fr);
   
    }
    .prodctall-main{
      width: auto;

    }
    .filter-container
    {

      margin-top: 35px;
  
    }
    .productall-box{
      width: 100%;
      height: 100%;
      padding: 7%;
    }
    .productall-box img{
      margin-bottom: 7%;
      width: 100%;
      height: 232px;
     }

  }

  @media (min-width:1601px){

    .prodctall-main {
      width: 100%;
    }
    .productall-container-filter {
      display: flex;
      flex-direction: row;
      width: auto;
    }
    .productall-container-filter2 {
      display: flex;
      flex-direction: row;
    }

    .productall-full-container{
      justify-content: center;
      gap: 1%;
    }
  
 
   .productall-container {
    grid-template-columns: repeat(4, 1fr);
 
  }
  .filter-container
    {
      margin-top: 34px;
      width: 100%;
    }
  .productall-box{
    width: 200px;
    padding: 7%;
  }
  .productall-btn{
    gap: 5%;
  }
  .productall-btn button{
    /* font-size: 10px; */
    font-weight: bold;
    padding-top: 2px;
    width: 128px;
    height: 36px;
  }
  .productall-btn img{
    margin-top: 4px;
  }
  .productall-container-filter .productall-container-filter {
    max-width: 1650px; 
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    
  }
  .productall-box img{
    width: 100%;
    height: 170px;
   }
  }