/* 
top-category-container */

@media (min-width: 360px) and (max-width:374px) {
  .top-category-container2 {

  }

  .top-category-container1 {
    text-align:left; 
    display: flex;
    flex-direction: column;
   
  }
}

@media (min-width: 375px) and (max-width:427px) {
  .top-category-container2 {

  }

  .top-category-container1 {
    text-align:left; 
    display: flex;
    flex-direction: row;
   
  }
}

@media (min-width:427px) and (max-width:768px)
{
  .top-category-container1{
    margin-top: 5%;
  }
  .top-category-container2 {

    margin-top: 4%;

  }
  /* .top-category-full{

  } */

}

@media (min-width:769px) and (max-width:1024px)
{
  .top-category-container1{
    margin-top: 5%;
    margin-left: 3%;
    margin-right: 2%;

  }
  .top-category-container2 {

    margin-top: 5%;
    gap: 25px;

  }
  /* .top-category-full{
  

  } */

}

@media (min-width:1025px) and (max-width:1200px)
{
  .top-category-container1{
    margin-top: 5%;
    margin-left: 3%;
    margin-right: 2%;

  }
  .top-category-container2 {

    margin-top: 5%;
    gap: 25px;

  }
  /* .top-category-full{
  

  } */

}
@media (min-width:1201px) and (max-width:1280px)
{
  .top-category-container1{
    margin-top: 5%;
  }
  .top-category-container2 {

    margin-top: 3%;
    gap: 25px;

  }
  .top-category-full{
    padding-left: 6%;
    padding-right: 5%;
  }

}

@media (min-width:1281px) and (max-width:1440px)
{
  .top-category-container1{
    margin-top: 5%;
  }
  .top-category-container2 {

    margin-top: 2%;

  }
  .top-category-full{
    padding-left: 5%;
    padding-right: 5%;
  }

}


@media (min-width:1441px) and (max-width:1536px)
{
  .top-category-container1{
    margin-top: 5%;
  }
  .top-category-container2 {

    margin-top: 2%;

  }
  .top-category-full{
    padding-left: 5%;
    padding-right: 5%;
  }

}


@media (min-width:1537px) and (max-width:1599px)
{
  .top-category-container1{
    margin-top: 5%;
  }
  .top-category-container2 {
    margin-top: 5%;
    gap: 25px;

  }
  .top-category-full{
    padding-left: 5%;
    padding-right: 5%;
  }

}

  @media (min-width:1600px) {

    .top-category-full{
      padding-left: 9%;
      padding-right:9%;


    }
    
   
  }