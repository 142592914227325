
.new-arrival-product, .new-arrival-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
}


@media (min-width: 360px) and (max-width: 376px)  {

  .new-arrival-product, .new-arrival-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .new-arrival-container {
    padding-top: 5%;
  }
  .new-arrival-box {
    width: 100%;
  }
  .productlist-full-container, .new-full-container {
    padding: 6%;
  }
  .product-list-pd{
    padding: 5%;
   }
   .new-arrival-box img{
    width: 100%;
    height: 224px;
  }
}


@media (min-width: 375px) and (max-width: 479px) {
  .new-arrival-product, .new-arrival-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .new-arrival-container {
    padding-top: 10%;
  }
  .new-arrival-box {
    width: 89%;
  }
  /* .productlist-full-container, .new-full-container {
  } */
  .product-list-pd{
   padding: 8%;
  }
  .new-arrival-box img{
    width: 100%;
    height: 224px;
  }
}


@media (min-width: 480px) and (max-width: 767px) {
  .new-arrival-product, .new-arrival-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .new-arrival-container {
    padding-top: 5%;
    flex-direction: row;
  }
  .new-arrival-box {
    width: 80%;
  }

  .new-arrival-box img{
    width: 100%;
    height: 224px;
  }
}


@media (min-width: 768px) and (max-width: 991px) {
  .new-arrival-container {
    padding-top: 3%;
    flex-direction: row;
  }
  .new-arrival-box {
    width: 86%;
  }

  .new-arrival-box img{
    width: 100%;
    height: 224px;
  }
  .scrollbar-hide {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .product-list-pd{
    padding: 3%;
   }
}


@media (min-width: 992px) and (max-width: 1024px) {
  .new-arrival-container {
    padding-top: 3%;
  }
  .new-arrival-box {
    width: 50%;
  }
  .productlist-full-container, .new-full-container {
    padding-left: 3%;
    padding-right: 2%;
  }
  .product-list-pd{
    padding: 2%;
   }
   .new-arrival-box img{
    width: 100%;
    height: 224px;
  }
}


@media (min-width: 1025px) and (max-width: 1200px) {
  .new-arrival-container {
    padding-top: 3%;
  }
  .new-arrival-box {
    width: 50%;
  }
  .productlist-full-container, .new-full-container {
    padding-left: 3%;
    padding-right: 2%;
  }
  .product-list-pd{
    padding: 2%;
   }
   .new-arrival-box img{
    width: 100%;
    height: 224px;
  }
}


@media (min-width: 1201px) and (max-width: 1280px) {
  .new-arrival-container {
    padding-top: 3%;
  }
  .new-arrival-box {
    width: 50%;
  }
  .productlist-full-container, .new-full-container {
    padding: 5%;
  }
  .new-arrival-box img{
    width: 100%;
    height: 224px;
  }
}
@media (min-width: 1281px) and (max-width: 1440px) {
  .new-arrival-container {
    padding-top: 3%;
  }
  .new-arrival-box {
    width: 100%;
  }
  .productlist-full-container, .new-full-container {
    padding-left: 5%;
    padding-right: 5%;
  }
  .new-arrival-box img{
    width: 100%;
    height: 224px;
  }
}


@media (min-width: 1441px) and (max-width: 1500px) {
  .new-arrival-container {
    padding-top: 3%;
  }
  .new-arrival-box {
    width: 50%;
  }
  .productlist-full-container, .new-full-container {
    padding-left: 5%;
    padding-right: 4%;
  }
  .new-arrival-box img{
    width: 100%;
    height: 224px;
  }
}
@media (min-width: 1501px) and (max-width: 1536px) {
  .new-arrival-container {
    padding-top: 3%;
  }
  .new-arrival-box {
    width: 50%;
  }
  .productlist-full-container, .new-full-container {
    padding-left: 5%;
    padding-right: 4%;
  }
  .new-arrival-box img{
    width: 100%;
    height: 224px;
  }
}

@media (min-width: 1537px) and (max-width: 1600px) {
  .new-arrival-container {
    padding-top: 3%;
  }
  .new-arrival-box {
    width: 50%;
  }
  .productlist-full-container, .new-full-container {
    padding-left: 5%;
    padding-right: 4%;
  }
  .new-arrival-box img{
    width: 100%;
    height: 224px;
  }
}



@media (min-width: 1601px) {
  .new-arrival-container {
    padding-top: 2%;
    flex-direction: row;
  }
  .new-arrival-box {
    width: 100%;
  }
  .productlist-full-container, .new-full-container {
    padding-left: 9%;
    padding-right: 9%;
  }
  .new-arrival-box img{
    width: 100%;
    height: 224px;
  }
}
