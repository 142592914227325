
  /* Banner-flex  */

  .Banner-flex1 {

    display: flex;

  }
  .Banner-flex2 {

    display: flex;

  }

  
/* Extra small devices (phones, 320px to 374px) */
@media (min-width: 320px) and (max-width: 374px) {

  .Banner-flex1{

    padding-top: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 3%;
  }
  .Banner-flex1 img{
    justify-content: center;
    width: 100%;
  }

  .Banner-flex2{
 
 margin-top: 20%;
    display: flex;
    flex-direction: column;
    padding: 3%;
    padding-bottom: 17%;
  }
  .Banner-flex2 img{
    justify-content: center;
    width: 100%;
  }
  .top-category-full{
    justify-content: center;
  }


}

/* Small devices (phones, 375px to 479px) */
@media (min-width: 375px) and (max-width: 424px) {

  .Banner-flex1{

    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 5%;
  }
  .Banner-flex1 img{
    justify-content: center;
    width: 100%;
  }

  .Banner-flex2{
 
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 4%;
    padding: 0%;
    margin-bottom: 20%;
    padding-top: 16%;

  }
  .Banner-flex2 img{
    justify-content: center;
    width: 100%;
   
  }
  .top-category-full{
    justify-content: center;
  }


}
@media (min-width: 425px) and (max-width: 479px) {

  .Banner-flex1{

    display: flex;
    flex-direction: column;

  
  }
  .Banner-flex1 img{
    justify-content: center;
    width: 100%;
  }

  .Banner-flex2{
    display: flex;
    flex-direction: column;
    margin-top: 0%;
    margin-bottom: 5%;
  }
  .Banner-flex2 img{
    justify-content: center;
    width: 100%;
   
  }

}

/* Small to medium devices (phones, 480px to 599px) */
@media (min-width: 480px) and (max-width: 599px) {

  .Banner-flex1{

    display: flex;
    flex-direction: column;

  
  }
  .Banner-flex1 img{
    justify-content: center;
    width: 100%;
  }

  .Banner-flex2{
    display: flex;
    flex-direction: column;
    margin-top: 0%;
    margin-bottom: 5%;
  }
  .Banner-flex2 img{
    justify-content: center;
    width: 100%;
   
  }

  

}

/* Medium devices (tablets, 600px to 767px) */
@media (min-width: 600px) and (max-width: 767px) {

  
  .Banner-flex1{

    display: flex;
    flex-direction: column;

  
  }
  .Banner-flex1 img{
    justify-content: center;
    width: 100%;
  }

  .Banner-flex2{
    display: flex;
    flex-direction: column;
    margin-top: 0%;
    margin-bottom: 5%;
  }
  .Banner-flex2 img{
    justify-content: center;
    width: 100%;
   
  }

}

/* Medium to large devices (tablets, 768px to 991px) */
@media (min-width: 768px) and (max-width: 991px) {


.Banner-flex1{
  display: flex;
  flex-direction: column;

}
.Banner-flex1 img{
  justify-content: center;
  width: 100%;
}

.Banner-flex2{
  display: flex;
  flex-direction: column;


}
.Banner-flex2 img{
  justify-content: center;
  width: 100%;
 
}

  
}

/* Large devices (desktops, 992px to 1000px) */
@media (min-width: 992px) and (max-width: 1000px) {

  .Banner-flex1{
    display: flex;
    flex-direction: row;
  }
  .Banner-flex1 img{
    justify-content: center;
    width:100%;
  }

  .Banner-flex2{
    display: flex;
    flex-direction: row;
    padding: 7%;
    padding-right: 20%;
  }
  .Banner-flex2 img{
    justify-content: center;
    width:100%;
   
  }
}

/* Extra large devices (large desktops, 1001px to 1024px) */
@media (min-width: 1001px) and (max-width: 1024px) {

  .Banner-flex1{
    display: flex;
    flex-direction: row;
  }
  .Banner-flex{
    padding: 10px;
  }
  .Banner-flex1 img{
    justify-content: center;
    width: 100%;
  }

  .Banner-flex2{
    display: flex;
    flex-direction: row;
  
  }
  .Banner-flex2 img{
    justify-content: center;
    width: 100%; 
  }

}

/* Extra extra large devices (larger desktops, 1025px to 1440px) */
@media (min-width: 1025px) and (max-width: 1200px)  {

  .Banner-flex1{
    display: flex;
    flex-direction: row;
  }
  .Banner-flex1 img{
    justify-content: center;
    width: 100%;
    height: 173px;
  }
  .Banner-flex{
    padding: 10px;
  }

  .Banner-flex2{
    display: flex;
    flex-direction: row;
  
  }
  .Banner-flex2 img{
    justify-content: center;
    width: 100%;
    height: 148px;
   
  }
}

@media (min-width: 1201px) and (max-width: 1280px) {

  .Banner-flex1{
    display: flex;
    flex-direction: row;
  }
  .Banner-flex1 img{
    justify-content: center;
    width: 100%;
  }
  .Banner-flex{
    padding-left: 64px;
    padding-right: 64px;
  }
  .Banner-flex2{
    display: flex;
    flex-direction: row;
  
  }
  .Banner-flex2 img{
    justify-content: center;
    width: 100%;
   
  }
}
@media (min-width: 1281px) and (max-width: 1440px) {

  .Banner-flex1{
    display: flex;
    flex-direction: row;
    padding-left: 5%;
    padding-right: 5%;
  }
  .Banner-flex1 img{
    justify-content: center;
    width: 100%;
  }

  .Banner-flex2{
    display: flex;
    flex-direction: row;
  
  }
  .Banner-flex2 img{
    justify-content: center;
    width: 100%;
   
  }

}

@media (min-width: 1441px) and (max-width: 1500px) {

  .Banner-flex1{
    display: flex;
    flex-direction: row;
    padding-left: 5%;
    padding-right: 5%;
  }
  .Banner-flex1 img{
    justify-content: center;
    width: 100%;
  }

  .Banner-flex2{
    display: flex;
    flex-direction: row;
  
  }
  .Banner-flex2 img{
    justify-content: center;
    width: 100%;
  }

}

@media (min-width: 1501px) and (max-width: 1536px) {

  .Banner-flex1{
    display: flex;
    flex-direction: row;
    padding-left: 5%;
    padding-right: 5%;
  }
  .Banner-flex1 img{
    justify-content: center;
    width: 100%;
  }

  .Banner-flex2{
    display: flex;
    flex-direction: row;
  
  }
  .Banner-flex2 img{
    justify-content: center;
    width: 100%;
   
  }

}


/* Ultra wide devices (1442px to 1600px ) */
@media (min-width:1537) and (max-width: 1600px) {
 
  .Banner-flex1{
    display: flex;
    flex-direction: row;
    padding-left: 176px;
    padding-right: 176px;
  }
  .Banner-flex1 img{
    justify-content: center;
    width:100%;
  }

  .Banner-flex2{
    display: flex;
    flex-direction: row;
  
  }
  .Banner-flex2 img{
    justify-content: center;
    
   
  }
}


@media (min-width: 1601px) {
 
  .Banner-flex1{
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .Banner-flex1 img{
    justify-content: center;
    width: 100%;
}
  

  .Banner-flex2{
    display: flex;
    flex-direction: row;
    justify-content: center;
  
  }
  .Banner-flex2 img{
    justify-content: center;
    width: 100%;
  }
}





