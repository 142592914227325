.section {
  display: none;
}

@media (min-width: 768px) and (max-width: 800px) {
  .section {
    display: block;
    margin-left: 3px;
    margin-right: 3px;
  }
  .section-box {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
  }
}

@media (min-width: 801px) and (max-width: 900px) {
  .section {
    display: block;
    margin-left: 2px;
    margin-right: 2px;
  }
  .section-box {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
  }
}
@media (min-width: 901px) and (max-width: 1000px) {
  .section {
    display: block;
    margin-left: 2px;
    margin-right: 2px;
  }
  .section-box {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
  }
}
@media (min-width: 1001px) and (max-width: 1023px) {
  .section {
    display: block;
    margin-left: 11px;
    margin-right: 11px;
  }
  .section-box {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
  }
}
@media (min-width: 1024px) and (max-width: 1279px) {
  .section {
    display: block;
    width: 60;
    padding: 7px;
  }
  .section-box {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
}

@media (min-width: 1280px) and (max-width: 1299px) {
  .section {
    display: block;
    margin-left: 32px;
    margin-right: 32px;

  }
  .section-box {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
}

@media (min-width: 1301px) and (max-width: 1439px) {
  .section {
    display: block;
    margin-left: 65px;
    margin-right: 40px;
  }
  .section-box {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
  }
}

@media (min-width: 1440px) and (max-width: 1535px) {
  .section {
    display: block;
    margin-left: 32px;
    margin-right: 32px;
  }
  .section-box {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

  }
}

@media (min-width: 1536px) and (max-width: 1599px) {
  .section {
    display: block;
    margin-left: 33px;
    margin-right: 33px;
  }
  .section-box {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
  }
}

@media (min-width: 1600px) {
  .section {
    display: block;
    margin-left: 5%;
    margin-right: 5%;
  }
  .section-box {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    
 
 
  }
}

.section-box .item {
  display: flex;
  
  align-items: center;
  
  background: white;

  height: 100%;
  text-align: left;
  padding: 20px;
}

.section-box img {
  max-height: 64px;
  max-width: 64px;
}

.section-box p {
  margin: 0;
}
