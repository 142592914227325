/* styles.css */
@media (max-width: 768px) {
    .modal-content {
      padding: 2rem;
      max-width: 90%;
    }
  }
  
  @media (max-width: 480px) {
    .modal-content {
      padding: 1rem;
      max-width: 95%;
    }
  
    .modal-content input, .modal-content select, .modal-content textarea {
      padding: 0.5rem;
    }
  
    .modal-content .button-group {
      flex-direction: row;
      gap: 1rem;
    }
  }

  @media (max-width: 375px) {
    .modal-content {
        padding: 2rem;
        max-width: 76%;
        gap: 10px;
    }
  
    .modal-content input, .modal-content select, .modal-content textarea {
      padding: 0.5rem;
    }
  
    .modal-content .button-group {
      flex-direction: row;
      gap: 1rem;
    }
  }

  @media (min-width: 360px) {
    .modal-content {
        padding: 2rem;
        max-width: 76%;
        gap: 10px;
    }
  
    .modal-content input, .modal-content select, .modal-content textarea {
      padding: 0.5rem;
    }
  
    .modal-content .button-group {
      flex-direction: row;
      gap: 1rem;
    }
  }
  