/* Responsive Table Container */
.responsive-table-container {
    overflow-x: auto;
  }
  
  /* Tracking Steps */
  

  .tracking-line {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 10px;
    background-color: #f87171;
  }
  
  @media (min-width: 360px) and (max-width:374px)  {

    .tracking-tablet{
      padding-top: 5%;
    }
    .tracking-line {
      top: 0;
      left: 21%;
      right: unset;
      height: 105%;
      width: 10px;
      transform: translateX(-50%);
      }
      .tracking-text{
        display: flex;
        flex-direction: row;
        justify-items: center;
        gap: 20px;
      }
    .tracking-steps {
        flex-direction: column;
      gap: 1rem;
    }
    .tracking-order{
      display: flex;
      flex-direction: column;
    }
  
    .tracking-steps .relative .w-14.h-14 {
      width: 2.5rem;
      height: 2.5rem;
    }
  
    .tracking-steps .relative .ml-3,
    .tracking-steps .relative .ml-4,
    .tracking-steps .relative .ml-9 {
      margin-left: 0.5rem;
    }
    .tracking-steps .relative .ml-2{
      margin-left: -1.5rem;
    }
    .tracking-steps .relative .ml-5{
      margin-left: -1.5rem;
    }
    .tracking-steps .relative .mlr-6{
      margin-left: -0.5rem;
    }
  }

  @media (min-width: 375px) and (max-width:424px)  {

    .tracking-tablet{
      padding-top: 5%;
    }
    .tracking-line {
        top: 0;
        left: 20%;
        right: unset;
        height: 105%;
        width: 10px;
        transform: translateX(-50%);
      }
      .tracking-text{
        display: flex;
        flex-direction: row;
        justify-items: center;
        gap: 20px;
      }
    .tracking-steps {
        flex-direction: column;
         gap: 1rem;
    }
    .tracking-order{
      display: flex;
      flex-direction: column;
    }
  
    .tracking-steps .relative .w-14.h-14 {
      width: 2.5rem;
      height: 2.5rem;
    }
  
    .tracking-steps .relative .ml-3,
    .tracking-steps .relative .ml-4,
    .tracking-steps .relative .ml-9 {
      margin-left: 0.5rem;
    }
    .tracking-steps .relative .ml-2{
      margin-left: -1.5rem;
    }
    .tracking-steps .relative .ml-5{
      margin-left: -1.5rem;
    }
    .tracking-steps .relative .mlr-6{
      margin-left: -0.5rem;
    }
  }

  @media (min-width: 425px) and (max-width:499px)  {

    .tracking-tablet{
      padding-top: 5%;
    }
    .tracking-line {
        top: 0;
        left: 17%;
        right: unset;
        height: 105%;
        width: 10px;
        transform: translateX(-50%);
      }
      .tracking-text{
        display: flex;
        flex-direction: row;
        justify-items: center;
        gap: 20px;
      }
    .tracking-steps {
        flex-direction: column;
      gap: 1rem;
    }
    .tracking-order{
      display: flex;
      flex-direction: column;
    }
  
    .tracking-steps .relative .w-14.h-14 {
      width: 2.5rem;
      height: 2.5rem;
    }
  
    .tracking-steps .relative .ml-3,
    .tracking-steps .relative .ml-4,
    .tracking-steps .relative .ml-9 {
      margin-left: 0.5rem;
    }
    .tracking-steps .relative .ml-2{
      margin-left: -1.5rem;
    }
    .tracking-steps .relative .ml-5{
      margin-left: -0.5rem;
    }
    .tracking-steps .relative .mlr-6{
      margin-left: -0.5rem;
    }
  }

  @media (min-width: 500px) and (max-width:599px)  {

    .tracking-tablet{
      padding-top: 5%;
    }
    .tracking-line {
        top: 0;
        left: 12%;
        right: unset;
        height: 105%;
        width: 10px;
        transform: translateX(-50%);
      }
      .tracking-text{
        display: flex;
        flex-direction: row;
        justify-items: center;
        gap: 20px;
      }
    .tracking-steps {
        flex-direction: column;
      gap: 1rem;
    }
    .tracking-order{
      display: flex;
      flex-direction: column;
    }
    .tracking-steps .relative .w-14.h-14 {
      width: 2.5rem;
      height: 2.5rem;
    }
  
    .tracking-steps .relative .ml-3,
    .tracking-steps .relative .ml-4,
    .tracking-steps .relative .ml-9 {
      margin-left: 0.5rem;
    }
  }

  @media (min-width: 600px) and (max-width:767px)  {

    .tracking-tablet{
      padding-top: 5%;
    }
    .tracking-line {
        top: 0;
        left: 10%;
        right: unset;
        height: 100%;
        width: 10px;
        transform: translateX(-50%);
      }
      .tracking-text{
        display: flex;
        flex-direction: row;
        justify-items: center;
        gap: 20px;
      }
    .tracking-steps {
        flex-direction: column;
      gap: 1rem;
    }
    .tracking-order{
      display: flex;
      flex-direction: column;
    }
 
  
    .tracking-steps .relative .w-14.h-14 {
      width: 2.5rem;
      height: 2.5rem;
    }
  
    .tracking-steps .relative .ml-3,
    .tracking-steps .relative .ml-4,
    .tracking-steps .relative .ml-9 {
      margin-left: 0.5rem;
    }


  }

  @media (min-width: 768px) and (max-width:800px) {

    .tracking-container{
      display: flex;
      flex-direction: column
     
    }
    .tracking-tablet{
      padding-top: 5%;
    }

    .tracking-steps {
      position: relative;
        flex-direction: row;
        justify-content: center;
        gap: 25px;
        margin-left: 8%;
    }
    .tracking-order-box{
      width: 100%;
    }
  
    .tracking-steps .relative .w-14.h-14 {
      width: 2.5rem;
      height: 2.5rem;
    }
  
    .tracking-icon1{
      margin-left: 1.0rem;
    }
    .tracking-icon2{
      margin-left: 1.6rem;
    }
    .tracking-icon3{
      margin-left: 0.6rem;

    }
    .tracking-icon4{
      margin-left: 0.2rem;
    }
  }

@media (min-width: 801px) and (max-width:900px) {

    .tracking-container{
      display: flex;
      flex-direction: column
     
    }

    .tracking-steps {
      position: relative;
      flex-direction: row;
      justify-content: center;
      gap: 23px;
      margin-left: 5%;
    }
    .tracking-order-box{
      width: 100%;
    }
  
    .tracking-steps .relative .w-14.h-14 {
      width: 2.5rem;
      height: 2.5rem;
    }
  
    .tracking-icon1{
      margin-left: 1.0rem;
    }
    .tracking-icon2{
      margin-left: 1.6rem;
    }
    .tracking-icon3{
      margin-left: 0.6rem;

    }
    .tracking-icon4{
      margin-left: 0.2rem;
    }
  }
  

  @media (min-width: 901px) and (max-width:1023px) {

    .tracking-container{
      display: flex;
      flex-direction: column
     
    }

    .tracking-steps {
      position: relative;
      flex-direction: row;
      justify-content: center;
      gap: 52px;
      margin-left: 7%;
    }
    .tracking-order-box{
      width: 100%;
    }
    .tracking-steps .relative .w-14.h-14 {
      width: 2.5rem;
      height: 2.5rem;
    }
  
    .tracking-icon1{
      margin-left: 1.0rem;
    }
    .tracking-icon2{
      margin-left: 1.6rem;
    }
    .tracking-icon3{
      margin-left: 0.6rem;

    }
    .tracking-icon4{
      margin-left: 0.2rem;
    }
  }
@media (min-width: 1024px) and (max-width:1200px) {

  .tracking-container{
      display: flex;
      flex-direction: column
     
    }

    .tracking-line {
        top: 43%;
      }
    .tracking-steps {
      flex-direction: row;
      justify-content: center;
      gap: 68px;
      margin-left: 10%;
    }
  
    .tracking-order-box{
      width: 100%;
    }
    .tracking-steps .relative {
      margin-bottom: 1rem;
    }
    .tracking-icon6{
      margin-left: 0.5rem;
    }

    .tracking-icon1{
      margin-left: 1.0rem;
    }
    .tracking-icon2{
      margin-left: 1.6rem;
    }
    .tracking-icon3{
      margin-left: 0.6rem;

    }
    .tracking-icon4{
      margin-left: 0.2rem;
    }
  }

@media (min-width: 1201px) and (max-width:1300px) {

    .tracking-container{
        display: flex;
        flex-direction: column
       
      }
  
      .tracking-line {
          top: 43%;
        }
      .tracking-steps {
        flex-direction: row;
        justify-content: center;
        gap: 122px;
        margin-left: 14%;
      }
      .tracking-order-box{
        width: 100%;
      }
    
      .tracking-steps .relative {
        margin-bottom: 1rem;
      }
  
      .tracking-icon1{
        margin-left: 0rem;
      }
      .tracking-icon2{
        margin-left: 1rem;
      }
      .tracking-icon3{
        margin-left: 0.6rem;
  
      }
      .tracking-icon4{
        margin-left: 0.2rem;
      }
      .tracking-icon5{
        margin-left: 0.2rem;
      }
      .tracking-icon6{
        margin-left: 0.2rem;
      }
    }

@media (min-width: 1301px) and (max-width:1439px) {

      .tracking-container{
          display: flex;
          flex-direction: column
         
        }
    
        .tracking-line {
            top: 43%;
          }
        .tracking-steps {
          flex-direction: row;
          justify-content: center;
          gap: 68px;
          margin-left: 14%;
        }
        .tracking-order-box{
          width: 100%;
        }
        .tracking-steps .relative {
          margin-bottom: 1rem;
        }
    
        .tracking-icon1{
          margin-left: 1.0rem;
        }
        .tracking-icon2{
          margin-left: 1.6rem;
        }
        .tracking-icon3{
          margin-left: 0.6rem;
    
        }
        .tracking-icon4{
          margin-left: 0.2rem;
        }
      }

@media (min-width: 1440px) and (max-width:1535px) {

  .tracking-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
      
          .tracking-line {
              top: 43%;
            }
          .tracking-steps {
            flex-direction: row;
            justify-content: center;
            gap: 42px;
            margin-left: 7%;
          }
        .tracking-order-box{
          width: 100%;
        }
      
          .tracking-steps .relative {
            margin-bottom: 1rem;
          }
      
          .tracking-icon1{
            margin-left: 1.0rem;
          }
          .tracking-icon2{
            margin-left: 1.6rem;
          }
          .tracking-icon3{
            margin-left: 0.6rem;
      
          }
          .tracking-icon4{
            margin-left: 0.2rem;
          }
          .tracking-icon5{
            margin-left: -1rem;
          }
        }

@media (min-width: 1536px) and (max-width:1599px) {

  .tracking-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
   
        
            .tracking-line {
                top: 43%;
              }
            .tracking-steps {
              flex-direction: row;
              justify-content: center;
              gap: 87px;
              margin-left: -1%;
            }
          .tracking-order-box{
            width: 100%;
          }
          .tracking-text1{
            margin-left: 1rem;
          }
          .tracking-text2{
            margin-left: 1rem;
          }
          .tracking-text3{
            margin-left: 0.5rem;
          }
        
            .tracking-steps .relative {
              margin-bottom: 1rem;
            }
        
            .tracking-icon1{
              margin-left: 1.0rem;
            }
            .tracking-icon2{
              margin-left: 1.6rem;
            }
            .tracking-icon3{
              margin-left: 0.6rem;
        
            }
            .tracking-icon4{
              margin-left: 0.2rem;
            }
          }

  @media (min-width: 1600px) {
    .tracking-container{
      display: flex;
      justify-content: center;
     
    }

    .tracking-line {
        top: 43%;
      }
    .tracking-steps {
      flex-direction: row;
      justify-content: center;
      gap: 90px;
      margin-left: -5%;
    }
    .tracking-order-box{
      width: 100%;
    }
  
  
    .tracking-steps .relative {
      margin-bottom: 1rem;
    }

    .tracking-icon1{
      margin-left: 5rem;
    }
    .tracking-icon6{
      margin-left: 0.5rem;
    }
    .tracking-text1{
      margin-left: 5rem;
    }
    .tracking-text2{
      margin-left: 1rem;
    }
    .tracking-text3{
      margin-left: 0.5rem;
    }
    .tracking-icon2{
      margin-left: 1.6rem;
    }
    .tracking-icon3{
      margin-left: 0.6rem;

    }
    .tracking-icon4{
      margin-right: 5rem;
    }
    .tracking-text4{
      margin-right: 5rem;
    }
  
  }
  
 
  