
/* Premium Product */

.premium-container{
  flex-direction: row;
  justify-content: center;
}
.premium{
  flex-direction: row;
  width:auto;
  gap:3px

}

.premium-product-box {
  width: 100%;
  height: 340px;
}


/* Extra small devices (phones, 320px to 374px) */
@media (min-width: 360px) and (max-width: 374px) {

  .banner-full-container {
    padding-top: 18%;
}

.premium-full-container{
  justify-content: center;
  padding: 3%;
}

  .premium-container{
    flex-direction: column;
    width: 100%;
  }
.premium{
  flex-direction: column;
  gap:18px  
  }

  .premium-container-img {
    width: 100%;
  }
  .today-flex-img{
    width: 100%;
  }

  .premium-product {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  .premium-product-box {
    width: 100%;
    height: 280px;
    font-size: small;
  }

  .premium-product  img {
    width: 90%;
    height: 135px;
  }

}

/* Small devices (phones, 375px to 479px) */
@media (min-width: 375px) and (max-width: 424px){

  .banner-full-container {
    padding-top: 18%;
}

.premium-full-container{
  justify-content: center;
  padding: 3%;
}

  .premium-container{
    flex-direction: column;
    width: 100%;
  }
.premium{
  flex-direction: column;
  gap:18px  
  }

  .premium-container-img {
    width: 100%;
  }
  .today-flex-img{
    width: 100%;
  }

  .premium-product {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  .premium-product-box {
    width: 100%;
    height: 285px;
    font-size: small;
  }

  .premium-product  img {
    width: 90%;
    height: 141px;
  }

}

@media (min-width: 425px) and (max-width: 479px) {




.premium-full-container{
  justify-content: center;
}

  .premium-container{
    flex-direction: column;
    width: 100%;
  }
.premium{
  flex-direction: column;
  gap:18px  
  }

  .premium-container-img {
    width: 75%;
    margin-left: 12%;
  }
  .today-flex-img{
    width: 75%;
    margin-left: 12%;
  }

  .premium-product {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  .premium-product-box {
    width: 100%;
    height: auto;
    font-size: small;
  }

  .premium-product  img {
    width: 90%;
    height: 172px;
  }

}

/* Small to medium devices (phones, 480px to 599px) */
@media (min-width: 480px) and (max-width: 599px) {




  .premium-full-container{
    justify-content: center;
  }
  
    .premium-container{
      flex-direction: column;
      width: 100%;
    }
  .premium{
    flex-direction: column;
    gap:18px  
    }
  
    .premium-container-img {
      width: 75%;
      margin-left: 12%;
    }
    .today-flex-img{
      width: 75%;
      margin-left: 12%;
    }
  
    .premium-product {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
    }
  
    .premium-product-box {
      width: 100%;
      height: auto;
      font-size: small;
    }
  
    .premium-product  img {
      width: 90%;
      height: 197px;
    }
  
  }
  

/* Medium devices (tablets, 600px to 767px) */
@media (min-width: 600px) and (max-width: 767px)  {

  .premium-full-container{
    justify-content: center;
  }
  
    .premium-container{
      flex-direction: column;
      width: 100%;
    }
  .premium{
    flex-direction: column;
    gap:18px  
    }
  
    .premium-container-img {
      width: 75%;
      margin-left: 12%
    }
    .today-flex-img{
      width: 75%;
      margin-left: 12%;
    }
  
    .premium-product {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
    }
    .premium-product-box {
      width: 100%;
      height: auto;
      font-size: medium;
    }
  
    .premium-product  img {
      width: 90%;
      height: 248px;
    }
  
  }
  
/* Medium to large devices (tablets, 768px to 991px) */
@media (min-width: 768px) and (max-width: 991px) {
  .banner-full-container {
    padding-top: 8%;
}

  .premium-full-container{

    padding-bottom: 4%;
    padding-top: 1%;
  } 
.product-img{
  width: 30px;
}
  .premium,
  .premium-container {
    flex-direction: row;
    width: auto;
    gap: 2%;
    
  }

  .premium {
    text-align: left;
  }

  .premium-product-box {
    width: 100%;
    height: 350px;
    font-size: medium;
  }

  .premium-product  img {
    width: 90%;
    height: 196px;
  }

  .premium-product {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
      gap: 10px;

  }


  
}

/* Large devices (desktops, 992px to 1000px) */
@media (min-width: 992px) and (max-width: 1000px) {

  .premium-container,
  .premium{
      flex-direction: row;
      width: auto;
      padding: 5%;
      
    }
  
    .premium {
      text-align: left;
    }
  
    .premium-container img {
      width: 100%;
      height: auto;
      margin: 0 auto 15px; /* Centering image and adding bottom margin */
    }
  
    .premium-product {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
      padding: 0 10px; /* Adding consistent padding */
    }
  
    .premium-product-box {
      width: 100%;
      height: 350px;
      font-size: medium;
    }
  
    .premium-product  img {
      height: 175px;
      width: 90%;
    }
  


}

/* Extra large devices (large desktops, 1001px to 1024px) */
@media (min-width: 1001px) and (max-width: 1024px) {

  .premium-container
  {
    justify-content: center;
    gap: 2%;
  }
  .premium{
      flex-direction: row;
      width: auto; 
    }
    .premium-full-container{

      padding-bottom: 4%;
      padding-top: 1%;
      padding: 2%;
    } 
  
    .premium {
      text-align: left;
    }
  
    .premium-container img {
      width: 100%;
      height: auto;
    }
  
    .premium-product {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 12px;

    }

    .premium-product-box {
      width: 100%;
      height: 350px;
      font-size: medium;
    }
  
    .premium-product  img {
      width: 90%;
      height: 179px;
    }
  
    .today-flex-img {
      width:50%;
      height:10%;
    }


}

/* Extra extra large devices (larger desktops, 1025px to 1440px) */
@media (min-width: 1025px) and (max-width: 1200px){

  .premium-container
  {
    justify-content: center;
  }
  .premium{
      flex-direction: row;
      width: auto; 
    }
    .premium-full-container{

      padding-bottom: 4%;
      padding-top: 1%;
      padding: 2%;
    } 
  
    .premium {
      text-align: left;
    }
  
    .premium-container img {
      width: 100%;
      height: auto;
    
    }
  
    .premium-product {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 12px;

    }
  

  
    .premium-product-box {
      width: 100%;
      height: 350px;
      font-size: medium;
    }
  
    .premium-product  img {
      width: 90%;
      height: 188px;
    }
    
    .today-flex-img {
      width:50%;
      height:10%;
    }


}

@media (min-width: 1201px) and (max-width: 1280px) {

  .premium,
  .premium-container {
    flex-direction: row;
    width: auto;
    
  }
  .premium-full-container{
    padding-left: 5%;
    padding-top: 1%;
    padding-right: 4%;
  }  
  .premium {
    text-align: left;
  }



  .premium-product {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
      gap: 20px;
   

  }

  
  .premium-product-box {
    width: 100%;
    height: auto;
    font-size: medium;
  }

  .premium-product  img {
    width: 90%;
    height: 182px;
  }

  .premium-container {
    flex-direction: row;
    justify-content: center;
    gap: 4%;
}



}

@media (min-width: 1281px) and (max-width: 1440px) {

  .premium,
  .premium-container {
    flex-direction: row;
    width: 100%; 
  }
  .premium-full-container{
    padding-left: 5%;
    padding-bottom: 4%;
    padding-top: 1%;
    padding-right: 5%;
  
  }  
  .premium {
    text-align: left;
  }

  .premium-product-box {
    width: 100%;
    height: 375px;
    font-size: medium;
  }

  .premium-product  img {
    width: 90%;
    height: 213px
  }


  .premium-product {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
   

  }
  .premium-container {
    flex-direction: row;
    justify-content: center;
    gap: 4%;
}





}

@media (min-width: 1441px) and (max-width: 1536px) {

  .premium,
  .premium-container {
    flex-direction: row;
    width: 100%; 
  }
  .premium-full-container{
    padding-left: 5%;
    padding-bottom: 4%;
    padding-top: 1%;
    padding-right: 5%;
  
  }  
  .premium {
    text-align: left;
  }


  .premium-product-box {
    width: 100%;
    height: 400px;
    font-size: medium;
  }

  .premium-product  img {
    width: 90%;
    height: 231px
  }

  .premium-product {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
   

  }
  .premium-container {
    flex-direction: row;
    justify-content: center;
    gap: 4%;
}




}


/* Ultra wide devices (1441px to 1600px) */
@media (min-width:1537px) and (max-width: 1600px) {
  .premium-full-container{

    padding-bottom: 4%;
    padding-top: 1%;
  } 

  .premium-product  {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
    margin-right: 13px;
    margin-left: 1%;
  }


  .premium-product-box {
    width: 100%;
    height: 400px;
    font-size: medium;
  }

  .premium-product  img {
    width: 90%;
    height: 231px
  }


  .premium-container {
    margin-left: 10px; 
  }
  
}

@media (min-width:1601px){
  
  .premium-full-container{
    padding-left: 9%;
    padding-right: 9%;
    padding-bottom: 4%;
    padding-top: 1%;
  }
  .premium-product  {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    margin-right: 30px; 
    margin-left: 20px;
  }

  .premium-product-box {
    width: 100%;
    height: 440px;
    font-size: medium;
  }

  .product-img {
    width: 90%;
    height: 254px;
  }

  /* .premium-container {

  } */
  
}

