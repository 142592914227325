  /* Footer Main */

  .foot-container1{

    display: flex;
    justify-content: center;
    gap: 300px;

  }
  .foot-container2{
    display: flex;
    
  }

  /* Extra small devices (phones, 320px to 374px) */
@media (min-width: 360px) and (max-width: 374px) {

.foot-container1 {
  flex-direction: column;

  padding-left: -2%;

  justify-content: center;
  padding: 4%;
  gap: 2%;

}


.foot-sub2 {
margin: 0;
width: 50%;
}

.foot-sub1 {

justify-content:left;
align-items:start;

}


.foot-sub2 {
margin-right: 100px;
text-align: left;
margin-bottom: 10%;
}

.foot-sub2 img {
  margin-right: -167px;

}

.foot-sub2 input {
  width: 225px;

}

.foot-container2 {
display: flex;
flex-direction: column;
gap: 40px;
padding-bottom: 20px;

}


}

/* Small devices (phones, 375px to 479px) */
@media (min-width: 375px) and (max-width: 479px) {
.foot-container1 {
  flex-direction: column;
  align-items: center;
  gap: 7px;
}

.foot-sub2 {
margin: 0;
width: 50%;
}
.foot-container1{
  padding-left: 0%;
  padding-right: 0%;
}

.foot-sub1 {

justify-content:left;
align-items:start;

}


.foot-sub2 {
  margin-right: 140px;
  text-align: left;
  margin-bottom: 10%;
}

.foot-sub2 img {

  margin-right: -134px;
/* margin-left: 100px; */
}

.foot-sub2 input {
width: 112%;

}

.foot-container2 {
display: flex;
flex-direction: column;
gap: 40px;
padding-bottom: 20px;

}

}

/* Small to medium devices (phones, 480px to 599px) */
@media (min-width: 480px) and (max-width: 599px) {
  .foot-container1 {
    flex-direction: column;
    align-items: center;
    gap: 7px;
  }
  
  .foot-sub2 {
  margin: 0;
  width: 50%;
  }
  
  .foot-sub1 {
  margin-right: 100px;
  justify-content:left;
  align-items:start;
  
  }
  
  
  .foot-sub2 {
  margin-right: 100px;
  text-align: left;
  margin-bottom: 10%;
  }
  
  .foot-sub2 img {
  margin-right:   -81px
  /* margin-left: 100px; */
  }
  
  .foot-sub2 input {
  width: 90%;
  
  }
  
  .foot-container2 {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-bottom: 20px;
  
  }

}

/* Medium devices (tablets, 600px to 767px) */
@media (min-width: 600px) and (max-width: 767px) {
  .foot-container1 {
    flex-direction: column;
    align-items: center;
    gap: 7px;
  }
  
  .foot-sub2 {
  margin: 0;
  width: 50%;
  }
  
  .foot-sub1 {
  margin-right: 100px;
  justify-content:left;
  align-items:start;
  
  }
  
  
  .foot-sub2 {
  margin-right: 100px;
  text-align: left;
  margin-bottom: 10%;
  }
  
  .foot-sub2 img {
  margin-right:   -81px
  /* margin-left: 100px; */
  }
  
  .foot-sub2 input {
  width: 90%;
  
  }
  
  .foot-container2 {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-bottom: 20px;
  
  }

}

/* Medium to large devices (tablets, 768px to 991px) */
@media (min-width: 768px) and (max-width: 820px) {

.foot-container1 {
  flex-direction: column;
  align-items: center;
  gap: 10px;

}

.foot-sub2 {
  margin: 0;
  width: 90%;
}

.foot-sub1 {

  justify-content:start;
  align-items:start;

}

.foot-sub2 {

  text-align: left;
  margin-bottom: 10%;
}
.foot-sub2 img {
  margin-right: 28px;
}

.foot-sub2 input {
  width: 80%;

}

.foot-container2 {
display: grid;
grid-template-columns: auto auto;
gap: 40px;
padding-bottom: 30px;

}

}

@media (min-width: 821px) and (max-width: 991px) {

  .foot-container1 {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  
  }
  
  .foot-sub2 {
    margin: 0;
    width: 90%;
  }
  
  .foot-sub1 {
  
    justify-content:start;
    align-items:start;
  
  }
  
  .foot-sub2 {
  
    text-align: left;
    margin-bottom: 10%;
  }
  .foot-sub2 img {
    margin-right: 56px;
  }
  
  .foot-sub2 input {
    width: 80%;
  
  }
  
  .foot-container2 {
  display: grid;
  grid-template-columns: auto auto;
  gap: 40px;
  padding-bottom: 30px;
  
  }
  
  }

/* Large devices (desktops, 992px to 1000px) */
@media (min-width: 992px) and (max-width: 1000px) {

  .foot-container1 {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  
  }
  
  .foot-sub2 {
    margin: 0;
    width: 90%;
  }
  
  .foot-sub1 {
  
    justify-content:start;
    align-items:start;
  
  }
  
  .foot-sub2 {
  
    text-align: left;
    margin-bottom: 10%;
  }
  .foot-sub2 img {
    margin-right: 56px;
  }
  
  .foot-sub2 input {
    width: 80%;
  
  }
  
  .foot-container2 {
  display: grid;
  grid-template-columns: auto auto;
  gap: 40px;
  padding-bottom: 30px;
  
  }

}

/* Extra large devices (large desktops, 1001px to 1024px) */
@media (min-width: 1001px) and (max-width: 1024px) {

.foot-container1 {
  flex-direction: row;
  padding-left: 4%;
  padding-right: 4%;

}

.foot-sub2 img {
  left: 360px;
}
.foot-sub2 input {
  width: 89%;

}



.foot-container2 {
display: grid;
grid-template-columns: auto auto auto auto;
gap: 40px;
padding-bottom: 30px;
}


}

/* Extra extra large devices (larger desktops, 1025px to 1440px) */
@media (min-width: 1025px) and (max-width: 1179px) {

  .foot-container1 {
    flex-direction: row;
    padding-left: 4%;
    padding-right: 4%;
  
  }
  
  .foot-sub2 img {
    left: 360px;
  }
  .foot-sub2 input {
    width: 89%;
  
  }
  
  
  
  .foot-container2 {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 40px;
  padding-bottom: 30px;
  }
  
  
  }
  
  @media (min-width: 1180px) and (max-width: 1279px) {

    .foot-container1 {
      flex-direction: row;
      padding-left: 4%;
      padding-right: 4%;
    
    }
    
    .foot-sub2 img {
      left: 412px;
    }
    .foot-sub2 input {
      width: 89%;
    
    }
    
    
    
    .foot-container2 {
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 40px;
    padding-bottom: 30px;
    }
    
    
    }

@media (min-width: 1280px) and (max-width: 1439px) {



 


  .foot-container1 {
    flex-direction: row;
    gap: 418px;
    padding-left: 9%;
    padding-right: 11%;
  }

  
  
  
  .foot-container2 {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 40px;
  padding-bottom: 30px;
  padding-left: 6%;
  padding-right: 6%;
  }
  
  
  
  .foot-sub2 img {
    left: 395px;
  }
  .foot-sub2 input {
    width: 100%;
  
  }
  
  }

  @media (min-width:1440px) and (max-width:1535px) {

  

    .foot-container1 {
      flex-direction: row;
      gap: 510px;
      padding-left: 6%;
      padding-right: 8%;
    }
  
    /* .foot-sub1{
      padding-left: 20%;
    } */
    
    .foot-container2 {
      padding-left: 6%;
      padding-right: 6%;
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 40px;
    padding-bottom: 30px;
    }
    
    
  
    .foot-sub2 img {
      left: 422px;
  }
    .foot-sub2 input {
      width: 100%;
    
    }
    
    
    }

/* Ultra wide devices (1442px to 1600px ) */
@media (min-width:1536px) and (max-width:1599px) {

  

  .foot-container1 {
    flex-direction: row;
    gap: 510px;
    padding-left: 6%;
    padding-right: 8%;
  }

  /* .foot-sub1{
    padding-left: 20%;
  } */
  
  .foot-container2 {
    padding-left: 6%;
    padding-right: 6%;
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 40px;
  padding-bottom: 30px;
  }
  
  

  .foot-sub2 img {
    left: 458px;
}
  .foot-sub2 input {
    width: 100%;
  
  }
  
  
  }

@media (min-width:1600px) {

  
  .foot-container1 {
    flex-direction: row;
    justify-content: space-between;
    padding-right: 383px;
    padding-left: 320px;
  }

  
  
  
  .foot-container2 {
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 76px;
    padding-bottom: 30px;
    justify-content: space-evenly;
    padding-left: 6%;
    padding-right: 7%;

  }
  
  
  .foot-sub2 img {
    left: 460px;

    }
    
    .foot-sub2 input {
    width: 100%;
    
    }
  
  
  }


