
@media (min-width:320px) and (max-width:374px)

{
    .manage-profile-main{
        display: flex;
        flex-direction: column;
        justify-items: center;
        gap: 5%;
        padding: 6%;
    }

.profile-details{
    width: 84%;
}
.address-profile-container{
    width: 95%;
    padding: 3%;
}

.profile-full-container{
    display: flex;
    flex-direction: column;
    padding: 6%;
}

.profile-banner-profile {
    width: 100%;
    padding-top: 4%;

}
.address-label {
    min-width: 4rem; 
  }

    .profile-dash{
        display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
   
    }
    .profile-dash-box{
        display: flex;
        flex-direction: column;
        width: 86%;
        height: 82%;
        padding: 7%;
        text-align: center;
    }
    .profiledashborde-text{
        text-align: center;
        font-size: 14px;
        text-wrap: nowrap;
    }


}

@media (min-width:375px) and (max-width:424px)

{
.manage-profile-main{
    display: flex;
    flex-direction: column;
    justify-items: center;
    gap: 5%;
    padding: 6%;
}

.profile-details{
    width: 84%;
}
.address-profile-container{
    width: 95%;
    padding: 3%;
}

.profile-full-container{
    display: flex;
    flex-direction: column;
    padding: 6%;
}

.profile-banner-profile {
    width: 100%;
    padding-top: 4%;

}
.address-label {
    min-width: 4rem; 
  }

    .profile-dash{
        display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
   
    }
    .profile-dash-box{
        display: flex;
        flex-direction: column;
        width: 86%;
        height: 82%;
        padding: 7%;
        text-align: center;
    }
    .profiledashborde-text{
        text-align: center;
        font-size: 14px;
        text-wrap: nowrap;
    }


}

@media (min-width:425px) and (max-width:500px)

{
    .manage-profile-main{
        display: flex;
    flex-direction: column;
    justify-items: center;
    gap: 5%;
    padding: 6%;
    }
    
    .profile-details{
        width: 84%;
    }
    .address-profile-container{
        width: 94%;
        padding: 3%;
    }
    
    .profile-full-container{
        display: flex;
        flex-direction: column;
        padding: 6%;
    }
    
    .profile-banner-profile {
        width: 100%;
        padding-top: 4%;
    
    }
    .address-label {
        min-width: 4rem; 
      }
    
        .profile-dash{
            display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 10px;
       
        }
        .profile-dash-box{
            display: flex;
            flex-direction: column;
            width: 84%;
            height: 78%;
            padding: 7%;
            text-align: center;
        }
        .profiledashborde-text{
            text-align: center;
            font-size: 14px;
            text-wrap: nowrap;
        }
    
    
}
@media (min-width:501px) and (max-width:689px)

{
    .manage-profile-main{
        display: flex;
    flex-direction: column;
    justify-items: center;
    gap: 5%;
    padding: 6%;
    }
    
    .profile-details{
        width: 88%;
    }
    .address-profile-container{
        width: 94%;
        padding: 3%;
    }
    
    .profile-full-container{
        display: flex;
        flex-direction: column;
        padding: 6%;
    }
    
    .profile-banner-profile {
        width: 100%;
        padding-top: 4%;
    
    }
    .address-label {
        min-width: 4rem; 
      }
    
        .profile-dash{
            display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 10px;
       
        }
        .profile-dash-box{
            display: flex;
            flex-direction: column;
            width: 84%;
            height: 78%;
            padding: 7%;
            text-align: center;
        }
        .profiledashborde-text{
            text-align: center;
            font-size: 14px;
            text-wrap: nowrap;
        }
    
    
}

@media (min-width:690px) and (max-width:768px)
{
    .manage-profile-main{
        display: flex;
        flex-direction: column;
        justify-items: center;
        gap: 5%;
        padding: 6%;
    }
    
    .profile-details{
        width: 92%;
    }
    .address-profile-container{
        width: 94%;
        padding: 3%;
    }
    
    .profile-full-container{
        display: flex;
        flex-direction: column;
        padding: 6%;
    }
    
    .profile-banner-profile {
        width: 100%;
        padding-top: 4%;
    
    }
    .address-label {
        min-width: 4rem; 
      }
    
        .profile-dash{
            display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 10px;
       
        }
        .profile-dash-box{
            display: flex;
            flex-direction: column;
            width: 84%;
            padding: 7%;
            text-align: center;
        }
        .profiledashborde-text{
            text-align: center;
            font-size: 14px;
            text-wrap: nowrap;
        }
    
    
}

@media (min-width:769px) and (max-width: 991px) 
{
    .manage-profile-main{
        display: flex;
        flex-direction: column;
        justify-items: center;
        gap: 5%;
        padding: 6%;
    }
    
    .profile-details{
        width: 92%;
    }
    .address-profile-container{
        width: 94%;
        padding: 3%;
    }
    
    .profile-full-container{
        display: flex;
        flex-direction: column;
        padding: 6%;
    }
    
    .profile-banner-profile {
        width: 100%;
        padding-top: 4%;
    
    }
    .address-label {
        min-width: 4rem; 
      }
    
        .profile-dash{
            display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 10px;
       
        }
        .profile-dash-box{
            display: flex;
            flex-direction: column;
            width: 84%;
            padding: 7%;
            text-align: center;
        }
        .profiledashborde-text{
            text-align: center;
            font-size: 14px;
            text-wrap: nowrap;
        }
    
    
}


@media (min-width:992px) and (max-width:1000px)
{
    .manage-profile-main{
        display: flex;
        flex-direction: row;
        justify-items: center;
        gap: 8%;
        padding: 4%;
    }
    
    .profile-details{
        width: 100%;
    }
    .address-profile-container{
        width: 94%;
        padding: 3%;
    }
    
    .profile-full-container{
        display: flex;
        flex-direction: row;
        padding: 4%;
        gap: 8%;
    }
    
    .profile-banner-profile {
        width: 10%; 
    }
    .order-list-parent{
        width: 70%;
    }
    .address-label {
        min-width: 4rem; 
      }
    
        .profile-dash{
            display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 10px;
       
        }
        .profile-dash-box{
            display: flex;
            flex-direction: column;
            width: 84%;
            padding: 7%;
            text-align: center;
        }
        .profiledashborde-text{
            text-align: center;
            font-size: 14px;
            text-wrap: nowrap;
        }
    
    
}
    
     @media (min-width:1001px) and (max-width:1024px)
      {
        .manage-profile-main{
            display: flex;
            flex-direction: row;
            justify-items: center;
            gap: 8%;
            padding: 4%;
        }
        
        .profile-details{
            width: 100%;
        }
        .address-profile-container{
            width: 94%;
            padding: 3%;
        }
        
        .profile-full-container{
            display: flex;
            flex-direction: row;
            padding: 4%;
            gap: 8%;
        }
        
        .profile-banner-profile {
            width: 100%; 
        }
        .profile-banner-profile-img{
            width: 100%;
        }
        .profile-banner-img{
            width: 100%;
        }
        .order-list-parent{
            width: 70%;
        }
        .address-label {
            min-width: 4rem; 
          }
        
            .profile-dash{
                display: grid;
              grid-template-columns: 1fr 1fr;
              gap: 10px;
           
            }
            .profile-dash-box{
                display: flex;
                flex-direction: column;
                width: 84%;
                padding: 7%;
                text-align: center;
            }
            .profiledashborde-text{
                text-align: center;
                font-size: 14px;
                text-wrap: nowrap;
            }
        
        
    }
        





@media (min-width:1025px) and (max-width:1200px)
{
    .manage-profile-main{
        display: flex;
        flex-direction: row;
        justify-items: center;
        gap: 8%;
        padding: 4%;
    }
    
    .profile-details{
        width: 100%;
    }
    .address-profile-container{
        width: 94%;
        padding: 3%;
    }
    
    .profile-full-container{
        display: flex;
        flex-direction: row;
        padding: 4%;
        gap: 8%;
    }
    
    .profile-banner-profile {
        width: 100%; 
    }
    .profile-banner-profile-img{
        width: 100%;
    }
    .profile-banner-img{
        width: 100%;
    }
    .order-list-parent{
        width: 70%;
    }
    .address-label {
        min-width: 4rem; 
      }
    
        .profile-dash{
            display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 10px;
       
        }
        .profile-dash-box{
            display: flex;
            flex-direction: column;
            width: 84%;
            padding: 7%;
            text-align: center;
        }
        .profiledashborde-text{
            text-align: center;
            font-size: 14px;
            text-wrap: nowrap;
        }
    
    
}
@media (min-width:1201px) and (max-width:1280px)
{
    .profile-full-container{
        padding-left: 1%;
        padding-right: 1%;
        gap: 70px
    }
    .profile-details{
        width: 100%;
    }
    .profile-dash-box{
        display: flex;
        flex-direction: column;
        width: 16%;
        height: auto;
      
    }
    .manage-profile-main{
        display: flex;
        justify-items: center;
        gap: 6%;
        padding-left: 9%;
        padding-right: 9%;
        
    }
    
    
    .address-profile-container{
        width: 93%;
        padding: 3%;    
    }
    .order-list-parent{
        width: 77%;
    }
    
    .profile-banner-profile {
        width: 100%; 
    }
    .profile-banner-profile-img{
        width: 100%;
    }
    .profile-banner-img{
        width: 100%;
    }
    .address-label {
        min-width: 8rem; 
      }
}
@media (min-width:1281px) and (max-width:1440px)
{
    .profile-full-container{
        padding-left: 2%;
        padding-right: 2%;
        gap: 70px
    }
    .profile-details{
        width: 100%;
    }
    .manage-profile-contain{
        width: 100%;
    }
    .profile-dash-box{
        display: flex;
        flex-direction: column;
        width: 16%;
        height: auto;
      
    }
    .manage-profile-main{
        display: flex;
        justify-items: center;
        gap: 6%;
        padding-left: 6%;
        padding-right: 6%;
    }
        
    .address-profile-container{
        width: 94%;
        padding: 3%;    
    }
    .order-list-parent{
        width: 77%;
    }
    
    .profile-banner-profile {
        width: 100%; 
    }
    .profile-banner-profile-img{
        width: 100%;
    }
    .profile-banner-img{
        width: 100%; 
    }
    .address-label {
        min-width: 8rem; 
      }
}

@media (min-width:1441px) and (max-width:1536px)
{
    .profile-full-container{
        padding-left: 5%;
        padding-right: 5%;
        gap: 70px
    }
    .profile-details{
        width: 100%;
    }
    .manage-profile-contain{
        width: 100%;
    }
    .profile-dash-box{
        display: flex;
        flex-direction: column;
        width: 16%;
        height: auto;
      
    }
    .manage-profile-main{
        display: flex;
        justify-items: center;
        gap: 6%;
        padding-left: 6%;
        padding-right: 6%;
    }
        
    .address-profile-container{
        width: 94%;
        padding: 3%;    
    }
    .order-list-parent{
        width: 77%;
    }
    
    .profile-banner-profile {
        width: 100%; 
    }
    .profile-banner-profile-img{
        width: 100%;
    }
    .profile-banner-img{
        width: 100%;
    }
    .address-label {
        min-width: 8rem; 
      }
}

@media (min-width:1537px) and (max-width:1600px)
{
    .profile-full-container{
        padding-left: 5%;
        padding-right: 5%;
        gap: 70px
    }
    .profile-details{
        width: 100%;
    }
    .manage-profile-contain{
        width: 100%;
    }
    .profile-dash-box{
        display: flex;
        flex-direction: column;
        width: 16%;
        height: auto;
      
    }
    .manage-profile-main{
        display: flex;
        justify-items: center;
        gap: 6%;
        padding-left: 6%;
        padding-right: 6%;
    }
        
    .address-profile-container{
        width: 94%;
        padding: 3%;    
    }
    .order-list-parent{
        width: 77%;
    }
    
    .profile-banner-profile {
        width: 100%; 
    }
    .profile-banner-profile-img{
        width: 100%;
    }
    .profile-banner-img{
        width: 100%;
    }
    .address-label {
        min-width: 8rem; 
      }
}


@media (min-width:1601px)
{
    .profile-full-container{
        padding-left: 10%;
        padding-right: 10%;
        gap: 5%;
        justify-content: center;
    }
    .profile-details{
        width: 100%;
    }
    .manage-profile-contain{
        width: 100%;
    }
    .profile-dash-box{
        display: flex;
        flex-direction: column;
        width: 16%;
        height: auto;
      
    }
    .manage-profile-main{
        display: flex;
        justify-items: center;
        gap: 5%;
        padding-left: 10%;
        padding-right: 10%;
    }
        
    .address-profile-container{
        width: 94%;
        padding: 3%;    
    }
    .order-list-parent{
        width: 77%;
    }
    
    .profile-banner-profile {
        width: 100%; 
    }
    .profile-banner-profile-img{
        width: 100%;
    }
    .profile-banner-img{
        width: 100%;
  
    }
    .address-label {
        min-width: 8rem; 
      }
}
