

/* search-icon-container */

.search-icon-container {
    display: none;
  }
  
  @media (max-width: 450px) {
    .search-icon-container {
      display: block;
    
    }
    .search-icon-container {
      border: 2px solid #ccc; /* Adjust the color and width as needed */
      border-radius: 50%; /* Make it a circle */
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fafafa; /* Adjust background color as needed */
      width: 30px; /* Make sure width and height are equal for a perfect circle */
      height: 30px;
      cursor: pointer;
    }
  }

  @media (max-width: 580px) {
    .search-icon-container {
      display: block;
    
    }
    .search-icon-container {
      border: 2px solid #ccc; /* Adjust the color and width as needed */
      border-radius: 50%; /* Make it a circle */
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fafafa; /* Adjust background color as needed */
      width: 30px; /* Make sure width and height are equal for a perfect circle */
      height: 30px;
      cursor: pointer;
    }
  }


  /* Custom scrollbar styles */
.search-results ul::-webkit-scrollbar {
  width: 3px;
}
  @media (min-width:600px) and (max-width:767px) {
    .search-results{
      left: 77px;
      top: 64%;
      width: 43%;
       }
  }

  @media (min-width:768px) and (max-width:899px) {
    .search-results{
      left: 77px;
      top: 64%;
      width: 43%;
       }
  }

  @media (min-width:900px) and (max-width:1023px) {
    .search-results{
      left: 32px;

       }
  }

  @media (min-width:1024px) and (max-width:1099px) {
    .search-results{
      left: -22px;
       }
  }

  @media (min-width:1100px) and (max-width:1279px) {
    .search-results{
      left: 46px;
       }
  }
  @media (min-width:1280px) and (max-width:1439px) {
    .search-results{
      left: 92px;
       }
  } 

  @media (min-width:1440px) and (max-width:1535px) {
    .search-results{
      left: 164px;
       }
  } 


@media (min-width:1536px) and (max-width:1599px) {
  .search-results{
    left: 208px;
     }
}  


  
@media (min-width:1600px) {

  .search-results{
 left: 381px;
  }
  
}


  /* Popup search bar */
  .search-popup {
    position: absolute;
    top: 4rem; 
    left: 0;
    right: 0;
    background-color: white;
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    z-index: 50;
  }
  
  .search-popup input {
    padding-left: 2.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    width: 81%;
    border-radius: 5.375rem;
    border: 1px solid #bcbcbc;
    outline: none;
  }
  
  