.product-view-petshop-banner{
    display: none;
}

/* Cart Button Styles */
.cart-button {
    transition: background-color 0.3s, fill 0.3s;
  }
  
  .cart-button:hover {
    background-color: #F03221;
  }
  
  .cart-button:hover .cart-icon {
    fill: white;
  }
  
  /* Favorite Button Styles */
  .favorite-button {
    transition: background-color 0.3s, fill 0.3s;
  }
  
  /* Apply styles conditionally based on state */
  .favorite-button.active {
    background-color: #F03221;
  }
  
  .favorite-button:hover .favorite-icon{
    fill: white;
  }
  .favorite-button:hover {
    background-color: #FFFF;
  }
  
  .favorite-button.active .favorite-icon {
    fill: white;
  }
  



  .review-list::-webkit-scrollbar {
    width: 3px; /* Customize scrollbar width */
  }
  .review-list::-webkit-scrollbar-thumb {
    background-color: #d6d6d6; /* Customize scrollbar thumb color */
    border-radius: 10px; /* Customize scrollbar thumb shape */
  }
  .review-list::-webkit-scrollbar-track {
    background: transparent; /* Customize scrollbar track color */
  }
@media (min-width:360px) and (max-width:374px)
{
    .product-view-container{
        display: flex;
        flex-direction: column;
        padding-top: 0%;
        padding: 4%;
    }
    .description-box{
      height: auto;
    }
    .description-box {
      overflow-y: hidden; 
    }
    .review-list {
      height: auto; 
      overflow-y: hidden; 
    }
    .custom-scrollbar-mini {
      overflow-x: auto; 
      -ms-overflow-style: none; 
      scrollbar-width: none; 
    }
    
    .custom-scrollbar-mini::-webkit-scrollbar {
      display: none;
    }

    .custom-scrollbar {
      overflow-x: auto; 
      -ms-overflow-style: none; 
      scrollbar-width: none; 
    }
  
    .custom-scrollbar::-webkit-scrollbar {
      display: none; 
    }
.review-bar {
  width: 59%;
}
.review-custom {
  padding-top: 6%;
}

    .product-view-banner{
        display:flex;
        flex-direction: column;
    }
    /* /* .product-view-msgbox{
        display: flex;
        flex-direction: column;
    } */


    .product-view-main-img{
        width: 100%;
    }
    .petshop-banner-img{
        width: 100%;
    }
    .review-custom-text{
     word-wrap: break-word;
     font-size: 10px;
    }

    .product-view-mini{
      display: flex;
      flex-direction: row;
    }

    .product-view-msgbox {
        display: flex;
        flex-direction: column;

      }
    
      .review-containe {
    
     position: relative;
        flex-direction: column; 
      }

      .review-box{
        position: relative;
        flex-direction: column;
        flex-direction: column;
        gap: 8%;
    
      }
      .review-containe{
        width: 90%;
        height: auto;
        font-size: smaller;
         
      }
      .add-review-btn{
        width: 250px;
      }
    
      .review-rating{
        width: 250px;
      }
  
}

@media (min-width:375px) and (max-width:424px)
{
  .product-view-container{
      display: flex;
      flex-direction: column;
      padding-top: 0%;
      padding: 4%;
  }
  .description-box{
    height: auto;
  }
  .description-box {
    overflow-y: hidden; 
  }
  .review-list {
    height: auto; 
    overflow-y: hidden; 
  }
  .custom-scrollbar-mini {
    overflow-x: auto; 
    -ms-overflow-style: none; 
    scrollbar-width: none; 
  }
  
  .custom-scrollbar-mini::-webkit-scrollbar {
    display: none;
  }

  .custom-scrollbar {
    overflow-x: auto; 
    -ms-overflow-style: none; 
    scrollbar-width: none; 
  }

  .custom-scrollbar::-webkit-scrollbar {
    display: none; 
  }
.review-bar {
width: 59%;
}
.review-custom {
padding-top: 6%;
}

  .product-view-banner{
      display:flex;
      flex-direction: column;
  }
  /* /* .product-view-msgbox{
      display: flex;
      flex-direction: column;
  } */


  .product-view-main-img{
      width: 100%;
  }
  .petshop-banner-img{
      width: 100%;
  }
  .review-custom-text{
   word-wrap: break-word;
   font-size: 10px;
  }

  .product-view-mini{
    display: flex;
    flex-direction: row;
  }

  .product-view-msgbox {
      display: flex;
      flex-direction: column;

    }
  
    .review-containe {
  
   position: relative;
      flex-direction: column; 
    }

    .review-box{
      position: relative;
      flex-direction: column;
      flex-direction: column;
      gap: 8%;
  
    }
    .review-containe{
      width: 90%;
      height: auto;
      font-size: smaller;
       
    }
    .add-review-btn{
      width: 250px;
    }
  
    .review-rating{
      width: 250px;
    }

}
@media (min-width:425px) and (max-width:430px)
{
  .product-view-container{
      display: flex;
      flex-direction: column;
      padding-top: 0%;
      padding: 4%;
  }
  .description-box{
    height: auto;
  }
  .description-box {
    overflow-y: hidden; 
  }
  .review-list {
    height: auto; 
    overflow-y: hidden; 
  }
  .custom-scrollbar-mini {
    overflow-x: auto; 
    -ms-overflow-style: none; 
    scrollbar-width: none; 
  }
  
  .custom-scrollbar-mini::-webkit-scrollbar {
    display: none;
  }

  .custom-scrollbar {
    overflow-x: auto; 
    -ms-overflow-style: none; 
    scrollbar-width: none; 
  }

  .custom-scrollbar::-webkit-scrollbar {
    display: none; 
  }
.review-bar {
width: 59%;
}
.review-custom {
padding-top: 6%;
}

  .product-view-banner{
      display:flex;
      flex-direction: column;
  }


  .product-view-main-img{
      width: 100%;
  }
  .petshop-banner-img{
      width: 100%;
  }
  .review-custom-text{
   word-wrap: break-word;
   font-size: 14px;
  }

  .product-view-mini{
    display: flex;
    flex-direction: row;
  }

  .product-view-msgbox {
      display: flex;
      flex-direction: column;

    }
  
    .review-containe {
  
   position: relative;
      flex-direction: column; 
    }

    .review-box{
      position: relative;
      flex-direction: column;
      flex-direction: column;
      gap: 8%;
  
    }
    .review-containe{
      width: 92%;
      height: auto;
      font-size:medium;
       
    }
    .add-review-btn{
      width: 250px;
    }
  
    .review-rating{
      width: 250px;
    }

}

@media (min-width:431px) and (max-width:567px)
{
  .product-view-container{
      display: flex;
      flex-direction: column;
      padding-top: 0%;
      padding: 5%;
  }
  .description-box{
    height: auto;
  }
  .description-box {
    overflow-y: hidden; 
  }
  .review-list {
    height: auto; 
    overflow-y: hidden; 
  }
  .custom-scrollbar-mini {
    overflow-x: auto; 
    -ms-overflow-style: none; 
    scrollbar-width: none; 
  }
  
  .custom-scrollbar-mini::-webkit-scrollbar {
    display: none;
  }

  .custom-scrollbar {
    overflow-x: auto; 
    -ms-overflow-style: none; 
    scrollbar-width: none; 
  }

  .custom-scrollbar::-webkit-scrollbar {
    display: none; 
  }
.review-bar {
width: 59%;
}
.review-custom {
padding-top: 6%;
}

  .product-view-banner{
      display:flex;
      flex-direction: column;
  }


  .product-view-main-img{
      width: 100%;
  }
  .petshop-banner-img{
      width: 100%;
  }
  .review-custom-text{
   word-wrap: break-word;
   font-size: 14px;
  }

  .product-view-mini{
    display: flex;
    flex-direction: row;
  }

  .product-view-msgbox {
      display: flex;
      flex-direction: column;

    }
  
    .review-containe {
  
   position: relative;
      flex-direction: column; 
    }

    .review-box{
      position: relative;
      flex-direction: column;
      flex-direction: column;
      gap: 8%;
  
    }
    .review-containe{
      width: 92%;
      height: auto;
      font-size:medium;
       
    }
    .add-review-btn{
      width: 250px;
    }
  
    .review-rating{
      width: 250px;
    }

}

@media (min-width:568px) and (max-width:600px)
{
  .product-view-container{
      display: flex;
      flex-direction: column;
      padding-top: 0%;
      padding: 5%;
  }
  .description-box{
    height: auto;
  }
  .description-box {
    overflow-y: hidden; 
  }
  .review-list {
    height: auto; 
    overflow-y: hidden; 
  }
  .custom-scrollbar-mini {
    overflow-x: auto; 
    -ms-overflow-style: none; 
    scrollbar-width: none; 
  }
  
  .custom-scrollbar-mini::-webkit-scrollbar {
    display: none;
  }

  .custom-scrollbar {
    overflow-x: auto; 
    -ms-overflow-style: none; 
    scrollbar-width: none; 
  }

  .custom-scrollbar::-webkit-scrollbar {
    display: none; 
  }
.review-bar {
width: 59%;
}
.review-custom {
padding-top: 6%;
}

  .product-view-banner{
      display:flex;
      flex-direction: column;
  }


  .product-view-main-img{
      width: 100%;
  }
  .petshop-banner-img{
      width: 100%;
  }
  .review-custom-text{
   word-wrap: break-word;
   font-size: 14px;
  }

  .product-view-mini{
    display: flex;
    flex-direction: row;
  }

  .product-view-msgbox {
      display: flex;
      flex-direction: column;

    }
  
    .review-containe {
  
   position: relative;
      flex-direction: column; 
    }

    .review-box{
      position: relative;
      flex-direction: column;
      flex-direction: column;
      gap: 8%;
  
    }
    .review-containe{
      width: 92%;
      height: auto;
      font-size:medium;
       
    }
    .add-review-btn{
      width: 250px;
    }
  
    .review-rating{
      width: 250px;
    }

}
@media (min-width:601px) and (max-width:768px)
{
  .product-view-container{
    display: flex;
    flex-direction: column;
    padding-top: 0%;
    padding: 6%;
}
.review-list {
  max-height: 160px; 
}
    .product-view-mini{
      display: flex;
      flex-direction: row;
    }

 .custom-scrollbar-mini {
  overflow-x: auto; 
  -ms-overflow-style: none; 
  scrollbar-width: none; 
}

.custom-scrollbar-mini::-webkit-scrollbar {
  display: none;
}
    .product-view-banner{
        display:flex;
        flex-direction: column;
    }
    .product-view-banner-details{
        align-items: center;
        
    }
 

    .buy-btn{
        width: 50%;
    
     }
     .addcarts-btn{
         display: flex;
         justify-content:start;
         gap: 10px;
     }
 
     .relate-box{
        
         width: 98%;
     }
    .product-view-main-img img{
      width: 100%;
  }

    .product-view-msgbox {
        display: flex;
        flex-direction: column;

        
       
      }
    
    
      .review-containe{
        width:95%;
        height: 400px;
  
         
      }
      .product-view-petshop-banner{
        display: block;
     
    }
    .product-view-petshop-banner img{
  
        width: 100%;
    }
      .review-box{
        display: flex;
      }
      .petshop-banner-img {
        display: none;
      }
}

@media (min-width:769px) and (max-width:968px)
{
  .product-view-container{
    display: flex;
    flex-direction: column;
    padding-top: 0%;
    padding: 6%;
}
.review-list {
  max-height: 160px; 
}
    .product-view-mini{
      display: flex;
      flex-direction: row;
    }

 .custom-scrollbar-mini {
  overflow-x: auto; 
  -ms-overflow-style: none; 
  scrollbar-width: none; 
}

.custom-scrollbar-mini::-webkit-scrollbar {
  display: none;
}
    .product-view-banner{
        display:flex;
        flex-direction: column;
    }
    .product-view-banner-details{
        align-items: center;
        
    }
 

    .buy-btn{
        width: 50%;
    
     }
     .addcarts-btn{
         display: flex;
         justify-content:start;
         gap: 10px;
     }
 
     .relate-box{
        
         width: 98%;
     }
    .product-view-main-img img{
      width: 100%;
  }

    .product-view-msgbox {
        display: flex;
        flex-direction: column;

        
       
      }
    
    
      .review-containe{
        width:95%;
        height: 400px;
  
         
      }
      .product-view-petshop-banner{
        display: block;
     
    }
    .product-view-petshop-banner img{
  
        width: 100%;
    }
      .review-box{
        display: flex;
      }
      .petshop-banner-img {
        display: none;
      }
}

@media (min-width:969px) and (max-width:1000px)
{
  .product-view-container{
    display: flex;
    flex-direction: column;
    padding-top: 0%;
    padding: 6%;
}
.review-list {
  max-height: 160px; 
}
    .product-view-mini{
      display: flex;
      flex-direction: row;
    }

 .custom-scrollbar-mini {
  overflow-x: auto; 
  -ms-overflow-style: none; 
  scrollbar-width: none; 
}

.custom-scrollbar-mini::-webkit-scrollbar {
  display: none;
}
    .product-view-banner{
        display:flex;
        flex-direction: column;
    }
    .product-view-banner-details{
        align-items: center;
        
    }
 

    .buy-btn{
        width: 50%;
    
     }
     .addcarts-btn{
         display: flex;
         justify-content:start;
         gap: 10px;
     }
 
     .relate-box{
        
         width: 98%;
     }
    .product-view-main-img img{
      width: 100%;
  }

    .product-view-msgbox {
        display: flex;
        flex-direction: column;

        
       
      }
    
    
      .review-containe{
        width:95%;
        height: 400px;
  
         
      }
      .product-view-petshop-banner{
        display: block;
     
    }
    .product-view-petshop-banner img{
  
        width: 100%;
    }
      .review-box{
        display: flex;
      }
      .petshop-banner-img {
        display: none;
      }
}


@media (min-width:1001px) and (max-width:1024px)
{
    
  .product-view-container{
      padding-left: 6%;
      padding-right: 6%;
    }
    .review-list {
      max-height: 160px; 
    }
    .product-view-banner-details{
      width: 100%;
    }

    .product-view-banner{
      display: flex;
      gap: 3%;
    }

    .product-view-msgbox{
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 2%;
    }


  .product-view-banner{
    display: flex;
    flex-direction: row;
    gap:3%;

  }



  .buy-btn{
      width: 50%;
  
   }
   .addcarts-btn{
       display: flex;
       justify-content:start;
       gap: 10px;
   }

   .relate-box{
      
    width: 95%;
   }
  .product-view-main-img img{
    width: 100%;
  }

  .product-view-msgbox1{
    width: 30%;
  }
  .product-view-msgbox2{
    width: 70%;
  }
  
  .product-view-msgbox {
      display: flex;
      flex-direction: row;
    }
  

    .review-containe{
      width: 100%;
      height: auto;
       
    }
    .review-track{

      width: 106%;
    }
    .product-view-petshop-banner{
      display: block;
      width: 107%;
    }
  .product-view-petshop-banner img{
    width: 100%;
    }

    .review-box{
      display: flex;
      flex-direction: column;
    }
    .review-list {
      max-height: 160px; 
    }
}

@media (min-width:1025px) and (max-width:1150px)
{
    
  .product-view-container{
      padding-left: 6%;
      padding-right: 6%;
    }
    .review-list {
      max-height: 160px; 
    }
    .product-view-banner-details{
      width: 100%;
    }

    .product-view-banner{
      display: flex;
      gap: 3%;
    }

    .product-view-msgbox{
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 3%;
    }


  .product-view-banner{
    display: flex;
    flex-direction: row;
    gap:3%;

  }



  .buy-btn{
      width: 50%;
  
   }
   .addcarts-btn{
       display: flex;
       justify-content:start;
       gap: 10px;
   }

   .relate-box{
      
    width: 95%;
   }
  .product-view-main-img img{
    width: 100%;
  }
  
  .product-view-msgbox {
      display: flex;
      flex-direction: row;
    }
  

    .review-containe{
      width: 102%;
      height: auto;
       
    }
    .review-track{
      margin-left: 30%;
      width: 106%;
    }
    .product-view-petshop-banner{
      display: block;
      width: 107%;
    }
  .product-view-petshop-banner img{
    width: 100%;
    height: 115px;
    }

    .review-box{
      display: flex;
      flex-direction: column;
    }
    .review-list {
      max-height: 160px; 
    }
}

@media (min-width:1151px) and (max-width:1280px)
{
    
  .product-view-container{
      padding-left: 6%;
      padding-right: 6%;
    }
    .review-list {
      max-height: 160px; 
    }
    .product-view-banner-details{
      width: 100%;
    }


    .product-view-msgbox{
      padding-right: 1%;
    }


  .product-view-banner{
    display: flex;
    flex-direction: row;


  }

  .buy-btn{
      width: 50%;
  
   }
   .addcarts-btn{
       display: flex;
       justify-content:start;
       gap: 10px;
   }

   .relate-box{
      
    width: 95%;
   }


  .product-view-main-img img{ 
    width: 100%; 
  }
  


  .product-view-msgbox {
      display: flex;
      flex-direction: row;
    }
  

    .review-containe{
      width: 100%;
      height: 370px;
       
    }
    .product-view-petshop-banner,.product-view-petshop-banner-img  {
      display: block;
      width: 102%;
      height: 168px;
    }

    .review-box{
      display: flex;
      justify-content: flex-start;
    }
}

@media (min-width:1281px) and (max-width:1440px)
{
    
  .product-view-container{
      padding-left: 6%;
      padding-right: 6%;
    }
    .review-list {
      max-height: 160px; 
    }
    .product-view-banner-details{
      width: 100%;
    }


    .product-view-msgbox{
      justify-content: center;
  }
  .product-view-msgbox1{
    width: 30%;
  }
  .product-view-msgbox2{
    width: 70%;
  }


  .product-view-banner{
    display: flex;
    flex-direction: row;


  }

  .buy-btn{
      width: 50%;
  
   }
   .addcarts-btn{
       display: flex;
       justify-content:start;
       gap: 10px;
   }

   .relate-box{
      
    width: 95%;
   }


  .product-view-main-img img{ 
    width: 100%; 
  }
  


  .product-view-msgbox {
      display: flex;
      flex-direction: row;
    }
  

    .review-containe{
      width: 100%;
      height: 370px;
       
    }
    .product-view-petshop-banner,.product-view-petshop-banner-img  {
      display: block;
      width: 102%;
    }

    .review-box{
      display: flex;
      justify-content: flex-start;
    }
}

@media (min-width:1441px) and (max-width:1536px)
{
    
  .product-view-container{
      padding-left: 7%;
      padding-right: 7%;
    }
    .review-list {
      max-height: 160px; 
    }
    .product-view-banner-details{
      width: 100%;
    }

    .product-view-banner{
      display: flex;
      gap: 3%;
    }

    .product-view-msgbox{

        justify-content: center;

    }
    .product-view-msgbox1{
      width: 30%;
    }
    .product-view-msgbox2{
      width: 70%;
    }

  .product-view-banner{
    display: flex;
    flex-direction: row;
    gap:3%;

  }

  .buy-btn{
      width: 50%;
  
   }
   .addcarts-btn{
       display: flex;
       justify-content:start;
       gap: 10px;
   }

   .relate-box{
      
    width: 95%;
   }


  .product-view-main-img img{ 
    width: 100%; 
  }
  


  .product-view-msgbox {
      display: flex;
      flex-direction: row;
    }
  

    .review-containe{
      width: 97%;
      height: 350px;
       
    }
    .product-view-petshop-banner {
      display: block;
      width: 100%;
    }
   .product-view-petshop-banner-img  {
      width: 100%;
    }

    .review-box{
      display: flex;
      justify-content: space-evenly;
    }
}

@media (min-width:1537px) and (max-width:1600px)
{
    
  .product-view-container{
      padding-left: 7%;
      padding-right: 7%;
    }
    .review-list {
      max-height: 160px; 
    }
    .product-view-banner-details{
      width: 100%;
    }

    .product-view-banner{
      display: flex;
      gap: 3%;
    }

    .product-view-msgbox{

        justify-content: center;

    }
    .product-view-msgbox1{
      width: 30%;
    }
    .product-view-msgbox2{
      width: 70%;
    }

  .product-view-banner{
    display: flex;
    flex-direction: row;
    gap:3%;

  }

  .buy-btn{
      width: 50%;
  
   }
   .addcarts-btn{
       display: flex;
       justify-content:start;
       gap: 10px;
   }

   .relate-box{
      
    width: 95%;
   }


  .product-view-main-img img{ 
    width: 100%; 
  }
  


  .product-view-msgbox {
      display: flex;
      flex-direction: row;
    }
  

    .review-containe{
      width: 97%;
      height: 350px;
       
    }
    .product-view-petshop-banner {
      display: block;
      width: 100%;
    }
   .product-view-petshop-banner-img  {
      width: 100%;
    }

    .review-box{
      display: flex;
      justify-content: flex-start;
    }
}

@media (min-width:1601px)
{
    
  .product-view-container{
      padding-left: 7%;
      padding-right: 7%;
    }
    .review-list {
      max-height: 160px; 
    }
    .product-view-banner-details{
      width: 100%;
    }

    .product-view-banner{
      display: flex;
      gap: 3%;
    }

    .product-view-msgbox{

        justify-content: center;

    }
    .product-view-msgbox1{
      width: 30%;
    }
    .product-view-msgbox2{
      width: 70%;
    }

  .product-view-banner{
    display: flex;
    flex-direction: row;
    gap:3%;

  }

  .buy-btn{
      width: 50%;
  
   }
   .addcarts-btn{
       display: flex;
       justify-content:start;
       gap: 10px;
   }

   .relate-box{
      
    width: 95%;
   }


  .product-view-main-img img{ 
    width: 100%; 
  }
  


  .product-view-msgbox {
      display: flex;
      flex-direction: row;
    }
  

    .review-containe{
      width: 97%;
      height: 350px;
       
    }
    .product-view-petshop-banner {
      display: block;
      width: 100%;
    }
   .product-view-petshop-banner-img  {
      width: 100%;
    }

    .review-box{
      display: flex;
      justify-content: flex-start;
    }
}

.review-bar {
  position: relative;
  background-color: #e0e0e0; 
}

.review-bar .filled-bar {
  background-color: #F03221;
  height: 100%;
  border-radius: 9999px; 
}

.review-bar {
  height: 8px;
  border-radius: 9999px;
}


