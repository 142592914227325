/* src/components/LoadingSpinner.css */

/* src/components/LoadingSpinner.css */

.loading-spinner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .spinner {
    display: flex;
    gap: 10px; /* Space between icons */
    align-items: center;
  }
  
  .spinner img {
    width: 50px; /* Size of each icon */
    height: 50px;
    opacity: 0; /* Start with hidden icons */
    animation: fadeInOut 2s infinite; /* Animation to show and hide icons */
  }
  
  @keyframes fadeInOut {
    0%, 100% {
      opacity: 0; /* Hidden at the start and end */
    }
    20%, 80% {
      opacity: 1; /* Fully visible at specific times */
    }
  }
  



/* 
.loading-spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeInBackground 1s forwards; 
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #ff0000;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeInBackground {
  0% {
    background-color: rgba(255, 255, 255, 0); 
  }
  100% {
    background-color: rgba(255, 255, 255, 0.8); 
  }
} */
