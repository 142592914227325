
/* Feed Back */




/* Extra small devices (phones, 320px to 374px) */
@media (min-width: 320px) and (max-width: 374px) {

    .feed-back-container{
      flex-direction: column;
      padding-bottom: 40%;
    }
    .customer-view{
      position: absolute;
      margin-left: -84%;
      margin-right: 80%;
      margin-top: 0px;
      width: 198px;
      padding: 5px;
    }
  
  }
  
  /* Small devices (phones, 375px to 479px) */
  @media (min-width: 375px) and (max-width: 479px) {
  
    
    .feed-back-container{
      flex-direction: column;
      padding-bottom: 40%;
    }
    .customer-view{
      position: absolute;
      margin-left: -72%;
      margin-right: 123%;
      margin-top: 0px;
      width: 204px;
      padding: 5px;
    }
  
  }
  
  /* Small to medium devices (phones, 480px to 599px) */
  @media (min-width: 480px) and (max-width: 599px) {
  
    
    .feed-back-container{
      flex-direction: column;
      padding-bottom: 40%;
    }
    .customer-view{
      position:absolute;
      margin-left: -50%;
      margin-right: 100%;
      margin-top: 0px;
      width: 200px;
      padding: 5px;
    }
    
  
  }
  
  /* Medium devices (tablets, 600px to 767px) */
  @media (min-width: 600px) and (max-width: 767px) {
  
    
    .feed-back-container{
      flex-direction: column;
      padding-bottom: 40%;
    }
    .customer-view{
      position:absolute;
      margin-left: -15%;
      margin-right: 100%;
      margin-top: 0px;
     
      width: 200px;
      padding: 9px;
    }
  
  }
  
  /* Medium to large devices (tablets, 768px to 991px) */
  @media (min-width: 768px) and (max-width: 991px) {
  
    .feed-back-container{
      flex-direction: column;
      padding-bottom: 20%;
    }
    .customer-view{
    
      margin-left: 8%;
      margin-top: 0px;
      width: 250px;
      padding: 10px;
    }
  
    
  }
  
  /* Large devices (desktops, 992px to 1000px) */
  @media (min-width: 992px) and (max-width: 1000px) {
  
    .customer-view{
  
      margin-left: 28%;
      margin-top: 0px;
      width: 250px;
      padding: 10px;
    }
  
    
  
  }
  
  /* Extra large devices (large desktops, 1001px to 1024px) */
  @media (min-width: 1001px) and (max-width: 1024px) {
  
    .customer-view{
  
      margin-left: 33%;
      margin-top: 0px;
      width: 250px;
      padding: 10px;
    }
    .customer-profile {
      width: 100%;
      padding: 2%;
    }
  
  
  }
  
  /* Extra extra large devices (larger desktops, 1025px to 1440px) */
  @media (min-width: 1025px) and (max-width: 1440px) {
  
    .customer-view{
  
      margin-left: 25%;
      margin-top: 0px;
      margin-bottom: 10%;
      width: 250px;
      padding: 10px;
    }
    .customer-profile {
      width:100% ;
    }
    .feed-back-container{
      padding-left: 5%;
      padding-right: 5%;
    }
  
  
  }
   /* Extra extra large devices (larger desktops, 1025px to 1440px) */
   @media (min-width: 1441px) and (max-width: 1536px) {
  
    .customer-view{
  
      margin-left: 25%;
      margin-top: 0px;
      margin-bottom: 10%;
      width: 250px;
      padding: 10px;
    }
    .customer-profile {
      width:105% ;
    }
    .feed-back-container{
      padding-left: 5%;
      padding-right: 8%;
    }
  
  
  }
  
  
  /* Ultra wide devices (1442px to 1600px ) */
  @media (min-width:1537) and (max-width: 1600px) {
  
    .customer-view{
  
      margin-left: 70%;
      margin-top: 0px;
      margin-bottom: 10%;
      width: 250px;
      padding: 10px;
    }
    .customer-profile {
      width:105% ;
    }
   
  }
  

  @media (min-width:1600px) {

    .feed-back-container{
      padding-left: 163px;
      padding-right: 257px;
  
    }
  
    .customer-view{
  
      margin-left: 29%;
      margin-top: -62px;
      margin-bottom: 10%;
      width: 250px;
      padding: 10px;
    }
    .customer-profile {
      width:105% ;
    }
   
  }
  