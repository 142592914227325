/* 


.top-brand-container{
    flex-direction: row;
    gap: 20px;
  }
  
  

  @media (min-width: 360px) and (max-width: 374px) {
  
    .top-brand-sub1 {
      display: grid;
      grid-template-columns: auto ;
      gap: 10px;
     
    }
  
    .top-brand-sub2{
      display: grid;
      grid-template-columns: auto ;
      gap: 20px;
  
    }
    .top-brand-sub-text {
      font-size:small;
    }
    .brand-box {
      padding: 8px;
      width: 280px;
      height: 50px;
    }
    .top-brand-btn{
    padding: 2%;
    width: 100px;
    }
    .top-brand-text{
      display: flex;
      flex-direction: column;
      gap: 2%;
      padding-bottom: 13%;
     }
     .top-brand-all{
      display: grid;
      grid-template-columns: auto ;
      gap: 20px;
      padding-left: 6%;
      padding-right: 6%;
      padding-top: 5%;
      padding-bottom: 5%;
     }

     .brand-box-all{
      width: 100%;
     }
  
    
  }
  

  @media (min-width: 375px) and (max-width: 424px) {
  
    .top-brand-sub1 {
      display: grid;
      grid-template-columns: auto ;
      gap: 10px;
     
    }
  
    .top-brand-sub2{
      display: grid;
      grid-template-columns: auto ;
      gap: 20px;
  
    }
    .top-brand-sub-text {
      font-size:small;
    }
    .brand-box {
      padding: 9px;
      width: 300px;
      height: 50px;
    }
    .top-brand-btn{
    padding: 2%;
    width: 100px;
    }
   
    .top-brand-text{
      display: flex;
      flex-direction: column;
      gap: 2%;
      padding-bottom: 13%;
     }

     .top-brand-all{
      display: grid;
      grid-template-columns: auto ;
      gap: 20px;
      padding-left: 8%;
      padding-right: 8%;
      padding-top: 7%;
      padding-bottom: 7%;
     }

     .brand-box-all{
      width: 100%;
     }
  
  }
  
  @media (min-width: 425px) and (max-width: 479px) {
  
    .top-brand-sub1 {
      display: grid;
      grid-template-columns: auto ;
      gap: 10px;
     
    }
  
    .top-brand-sub2{
      display: grid;
      grid-template-columns: auto ;
      gap: 20px;
  
    }
    .top-brand-sub-text {
      font-size:small;
    }
    .brand-box {
      padding: 8px;
      width: 338px;
      height: 50px;

    }
    .top-brand-btn{
    padding: 2%;
    width: 100px;
    }
   
   
    .top-brand-all{
      display: grid;
      grid-template-columns: auto ;
      gap: 20px;
      padding-left: 8%;
      padding-right: 8%;
      padding-top: 7%;
      padding-bottom: 7%;
     }

     .brand-box-all{
      width: 100%;
     }
  
  }
  


  @media (min-width: 480px) and (max-width: 599px) {
  
    .top-brand-sub1 {
      display: grid;
      grid-template-columns: auto ;
      gap: 10px;
     
    }
  
    .top-brand-sub2{
      display: grid;
      grid-template-columns: auto ;
      gap: 20px;
  
    }
    .top-brand-sub-text {
      font-size:small;
    }
    .brand-box {
      padding: 9px;
      width: 420px;
      height: 50px;
    }
    .top-brand-btn{
    padding: 2%;
    width: 100px;
    }
   
    .top-brand-all{
      display: grid;
      grid-template-columns: auto auto ;
      gap: 20px;
      padding-left: 10%;
      padding-right: 10%;
      padding-top: 7%;
      padding-bottom: 7%;
     }

     .brand-box-all{
      width: 100%;
     }
  
  }
  

  @media (min-width: 600px) and (max-width: 700px) {
    .top-brand-container{
      padding-left: 1%;
      padding-right: 1%;
    }
    .top-brand-sub1 {
      display: flex;
    
   
     
    }
    .top-brand-sub2{
      display: grid;
      grid-template-columns: auto auto;
  
    }
    .brand-box {
      padding: 8px;
      width: 240px;
      height: 50px;
    }

    .top-brand-all{
      display: grid;
      grid-template-columns: auto auto ;
      gap: 20px;
      padding-left: 10%;
      padding-right: 10%;
      padding-top: 7%;
      padding-bottom: 7%;
     }

     .brand-box-all{
      width: 100%;
     }

  
  }
  @media (min-width: 701px) and (max-width: 768px) {
    .top-brand-container{
      padding-left: 1%;
      padding-right: 1%;
    }
    .top-brand-sub1 {
      display: flex;
    
   
     
    }
    .top-brand-sub2{
      display: grid;
      grid-template-columns: auto auto auto;
      gap: 20px;
  
    }
    .brand-box {
      padding: 8px;
      width: 204px;
      height: 50px;
    }

    .top-brand-all{
      display: grid;
      grid-template-columns: auto auto ;
      gap: 30px;
      padding-left: 6%;
      padding-right: 6%;
      padding-top: 7%;
      padding-bottom: 7%;
     }

     .brand-box-all{
      width: 100%;
     }

  
  }
  
  

  @media (min-width: 769px) and (max-width: 991px) {
  
    .top-brand-sub2{
      display: grid;
      grid-template-columns: auto auto auto;
      gap: 20px;
  
    }
    .brand-box {
      padding: 8px;
      width: 200px;
      height: 50px;
    }

    .top-brand-all{
      display: grid;
      grid-template-columns: auto auto ;
      gap: 30px;
      padding-left: 6%;
      padding-right: 6%;
      padding-top: 7%;
      padding-bottom: 7%;
     }

     .brand-box-all{
      width: 100%;
     }
    
  }
  

  @media (min-width: 992px) and (max-width: 1000px) {
  
    .top-brand-sub2{
      display: grid;
      grid-template-columns: auto auto auto;
      gap: 20px;
  
    }
    .brand-box {
      padding: 8px;
      width: 230px;
      height: 50px;
    }

    .top-brand-all{
      display: grid;
      grid-template-columns: auto auto ;
      gap: 30px;
      padding-left: 6%;
      padding-right: 6%;
      padding-top: 7%;
      padding-bottom: 7%;
     }

     .brand-box-all{
      width: 100%;
     }
  
  }

  @media (min-width: 1001px) and (max-width: 1024px) {
  
    .top-brand-sub2{
      display: grid;
      grid-template-columns: auto auto auto;
      gap: 20px;
  
    }
    .brand-box {
      padding: 8px;
      width: 250px;
      height: 50px;
    }

    .top-brand-all{
      display: grid;
      grid-template-columns: auto auto auto;
      gap: 30px;
      padding-left: 6%;
      padding-right: 6%;
      padding-top: 7%;
      padding-bottom: 7%;
     }

     .brand-box-all{
      width: 100%;
     }
  
  }
  

  @media (min-width: 1025px) and (max-width: 1440px) {
  
    .top-brand-sub2{
      display: flex;
      flex-direction: row;
      gap: 10px;
  
    }
    .brand-box {
      padding: 8px;
      width: 250px;
      height: 50px;
    }
    .top-brand-all{
      display: grid;
      grid-template-columns: auto auto auto;
      gap: 30px;
      padding-left: 6%;
      padding-right: 6%;
      padding-top: 7%;
      padding-bottom: 7%;
     }

     .brand-box-all{
      width: 100%;
     }
  
  }
  
  @media (min-width: 1441px) {
  
    .top-brand-sub2{
      display: flex;
      flex-direction: row;
      gap: 20px;
  
    }
    .brand-box {
      padding: 8px;
      width: 250px;
      height: 50px;
    }
    .top-brand-all{
      display: grid;
      grid-template-columns: auto auto auto;
      gap: 30px;
      padding-left: 6%;
      padding-right: 6%;
      padding-top: 4%;
      padding-bottom: 7%;
     }

     .brand-box-all{
      width: 100%;
     }
  
  }


  @media (min-width:1536px) {
  
    .top-brand-sub2{
      display: flex;
      flex-direction: row;
      gap: 20px;
  
    }
    .brand-box {
      padding: 8px;
      width: 400px;
      height: 50px;
    }
    .top-brand-all{
      display: grid;
      grid-template-columns: auto auto auto;
      gap: 30px;
      padding-left: 6%;
      padding-right: 6%;
      padding-top: 3%;
      padding-bottom: 7%;
     }

     .brand-box-all{
      width: 100%;
     }
   
  }


   @media (min-width:1537) and (max-width: 1600px) {
  
    .top-brand-sub2{
      display: flex;
      flex-direction: row;
      gap: 20px;
  
    }
    .brand-box {
      padding: 8px;
      width: 400px;
      height: 50px;
    }
    .top-brand-all{
      display: grid;
      grid-template-columns: auto auto auto auto;
      gap: 30px;
      padding-left: 6%;
      padding-right: 6%;
      padding-top: 4%;
      padding-bottom: 7%;
     }

     .brand-box-all{
      width: 100%;
     }
   
  }
  
  
  @media (min-width:1601px)
  {
    .top-brand-sub2{
      display: flex;
      flex-direction: row;
      gap: 20px;
  
    }
    .brand-box {
      padding: 8px;
      width: 100%;
      height: 50%;
    }
    .top-brand-all{
      display: grid;
      grid-template-columns: auto auto auto auto;
      gap: 30px;
      padding-left: 6%;
      padding-right: 6%;
      padding-top: 3%;
      padding-bottom: 7%;
     }

     .brand-box-all{
      width: 100%;
     }

  }
   */


   
/* Top Brand */

.top-brand-container{
  flex-direction: row;
  gap: 20px;
}


/* Extra small devices (phones, 320px to 374px) */
@media (min-width: 360px) and (max-width: 374px) {

  .top-brand-sub1 {
    display: grid;
    grid-template-columns: auto ;
    gap: 10px;
   
  }
  .coupons-grid{
    display: grid;
    grid-template-columns: auto ;
    gap: 3%;
  }

  .top-brand-sub2{
    display: grid;
    grid-template-columns: auto ;
    gap: 20px;

  }
  .top-brand-sub-text {
    font-size:small;
  }
  .brand-box {
    padding: 8px;
    width: 280px;
    height: 50px;
  }
  .top-brand-btn{
  padding: 2%;
  width: 100px;
  }
  .top-brand-text{
    display: flex;
    flex-direction: column;
    gap: 2%;
    padding-bottom: 13%;
   }
   .top-brand-all{
    display: grid;
    grid-template-columns: auto ;
    gap: 20px;
    padding-left: 6%;
    padding-right: 6%;
    padding-top: 5%;
    padding-bottom: 5%;
   }

   .brand-box-all{
    width: 100%;
   }
   .top-brand-container{
    padding-top: 1%;
  }
}

/* Small devices (phones, 375px to 479px) */
@media (min-width: 375px) and (max-width: 424px) {

  .top-brand-sub1 {
    display: grid;
    grid-template-columns: auto ;
    gap: 10px;
   
  }

  .top-brand-sub2{
    display: grid;
    grid-template-columns: auto ;
    gap: 20px;

  }
  .coupons-grid{
    display: grid;
    grid-template-columns: auto ;
    gap: 3%;
  }
  .top-brand-sub-text {
    font-size:small;
  }
  .brand-box {
    padding: 9px;
    width: 300px;
    height: 50px;
  }
  .top-brand-btn{
  padding: 2%;
  width: 100px;
  }
 
  .top-brand-text{
    display: flex;
    flex-direction: column;
    gap: 2%;
    padding-bottom: 13%;
   }
   
   .top-brand-all{
    display: grid;
    grid-template-columns: auto ;
    gap: 20px;
    padding-left: 8%;
    padding-right: 8%;
    padding-top: 7%;
    padding-bottom: 7%;
   }

   .brand-box-all{
    width: 100%;
   }
   .top-brand-container{
    padding-top: 1%;
  }
}

@media (min-width: 425px) and (max-width: 479px) {

  .top-brand-sub1 {
    display: grid;
    grid-template-columns: auto ;
    gap: 10px;
   
  }
  .coupons-grid{
    display: grid;
    grid-template-columns: auto ;
    gap: 3%;
  }
  .top-brand-sub2{
    display: grid;
    grid-template-columns: auto ;
    gap: 20px;

  }
  .top-brand-sub-text {
    font-size:small;
  }
  .brand-box {
    padding: 8px;
    width: 338px;
    height: 50px;

  }
  .top-brand-btn{
  padding: 2%;
  width: 100px;
  }
 
    
  .top-brand-all{
    display: grid;
    grid-template-columns: auto ;
    gap: 20px;
    padding-left: 8%;
    padding-right: 8%;
    padding-top: 7%;
    padding-bottom: 7%;
   }

   .brand-box-all{
    width: 100%;
   }
   .top-brand-container{
    padding-top: 1%;
  }
}


/* Small to medium devices (phones, 480px to 599px) */
@media (min-width: 480px) and (max-width: 599px) {

  .top-brand-sub1 {
    display: grid;
    grid-template-columns: auto ;
    gap: 10px;
   
  }
  .coupons-grid{
    display: grid;
    grid-template-columns: auto ;
    gap: 3%;
  }
  .top-brand-sub2{
    display: grid;
    grid-template-columns: auto ;
    gap: 20px;

  }
  .top-brand-sub-text {
    font-size:small;
  }
  .brand-box {
    padding: 9px;
    width: 420px;
    height: 50px;
  }
  .top-brand-btn{
  padding: 2%;
  width: 100px;
  }
  .top-brand-all{
    display: grid;
    grid-template-columns: auto auto ;
    gap: 20px;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 7%;
    padding-bottom: 7%;
   }

   .brand-box-all{
    width: 100%;
   }

   .top-brand-container{
    padding-top: 1%;
  }
  

}

/* Medium devices (tablets, 600px to 767px) */
@media (min-width: 600px) and (max-width: 700px) {
  .top-brand-container{
    padding-left: 1%;
    padding-right: 1%;
  }
  .top-brand-sub1 {
    display: flex;
  
 
   
  }
  .coupons-grid{
    display: grid;
    grid-template-columns: auto ;
    gap: 3%;
  }
  .top-brand-sub2{
    display: grid;
    grid-template-columns: auto auto;

  }
  .brand-box {
    padding: 8px;
    width: 240px;
    height: 50px;
  }
  
  .top-brand-all{
    display: grid;
    grid-template-columns: auto auto ;
    gap: 20px;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 7%;
    padding-bottom: 7%;
   }

   .brand-box-all{
    width: 100%;
   }
   .top-brand-container{
    padding-top: 1%;
  }


}
@media (min-width: 701px) and (max-width: 768px) {

  .top-brand-sub1 {
    display: flex;
  
 
   
  }

  .coupons-grid{
    display: grid;
    grid-template-columns: auto ;
    gap: 3%;
  }
  .top-brand-sub2{
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 20px;
    justify-content:center;

  }
  .brand-box {
    padding: 8px;
    width: 204px;
    height: 50px;
  }
  .top-brand-all{
    display: grid;
    grid-template-columns: auto auto ;
    gap: 30px;
    padding-left: 6%;
    padding-right: 6%;
    padding-top: 7%;
    padding-bottom: 7%;
   }

   .brand-box-all{
    width: 100%;
   }
   .top-brand-container{
    padding-top: 1%;
  }

}


/* Medium to large devices (tablets, 768px to 991px) */
@media (min-width: 769px) and (max-width: 991px) {

  .top-brand-sub2{
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 20px;

  }
  .brand-box {
    padding: 8px;
    width: 200px;
    height: 50px;
  }
  .top-brand-all{
    display: grid;
    grid-template-columns: auto auto ;
    gap: 30px;
    padding-left: 6%;
    padding-right: 6%;
    padding-top: 7%;
    padding-bottom: 7%;
   }

   .brand-box-all{
    width: 100%;
   }
   .top-brand-container{
    padding-top: 1%;
  }
  
  
}

/* Large devices (desktops, 992px to 1000px) */
@media (min-width: 992px) and (max-width: 1000px) {

  .top-brand-sub2{
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 20px;

  }
  .brand-box {
    padding: 8px;
    width: 230px;
    height: 50px;
  }
  .top-brand-all{
    display: grid;
    grid-template-columns: auto auto ;
    gap: 30px;
    padding-left: 6%;
    padding-right: 6%;
    padding-top: 7%;
    padding-bottom: 7%;
   }

   .brand-box-all{
    width: 100%;
   }
   .top-brand-container{
    padding-top: 1%;
  }

}

/* Extra large devices (large desktops, 1001px to 1024px) */
@media (min-width: 1001px) and (max-width: 1024px) {

  .top-brand-sub2{
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 20px;

  }
  .coupons-grid{
    display: grid;
    grid-template-columns: auto ;
    gap: 3%;
  }
  .brand-box {
    padding: 8px;
    width: 250px;
    height: 50px;
  }

  .top-brand-all{
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 30px;

   }

   .brand-box-all{
    width: 90%;
   }
   .top-brand-container{
    padding-top: 1%;
  }
}

/* Extra extra large devices (larger desktops, 1025px to 1440px) */
@media (min-width: 1025px) and (max-width: 1440px) {

  .top-brand-sub2{
    display: flex;
    flex-direction: row;
    gap: 20px;

  }

  .coupons-grid{
    display: grid;
    grid-template-columns: auto auto ;
    gap: 2%;
  }
  .brand-box {
    padding: 8px;
    width: 250px;
    height: 50px;
  }
  .top-brand-all{
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 30px;
    padding: 3%;
   }

   .brand-box-all{
    width: 100%;
   }
   .top-brand-container{
    padding-top: 1%;
  }

}

@media (min-width: 1441px) {

  .top-brand-sub2{
    display: flex;
    flex-direction: row;
    gap: 20px;

  }
  .brand-box {
    padding: 8px;
    width: 250px;
    height: 50px;
  }

  .coupons-grid{
    display: grid;
    grid-template-columns: auto auto ;
    gap: 2%;
  }
  .top-brand-all{
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 30px;
   }

   .brand-box-all{
    width: 100%;
   }
   .top-brand-container{
    padding-top: 1%;
  }

}

/* Ultra wide devices (1442px to 1600px ) */
@media (min-width:1536px) {

  .top-brand-sub2{
    display: flex;
    flex-direction: row;
    gap: 20px;

  }
  .brand-box {
    padding: 8px;
    width: 50%;
    height: 50px;
  }
  .top-brand-all{
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 30px;
   }
   .coupons-grid{
    display: grid;
    grid-template-columns: auto auto ;
    gap: 2%;
  }

   .brand-box-all{
    width: 100%;
   }
   .top-brand-container{
    padding-top: 1%;
  }
}

 /* Ultra wide devices (1442px to 1600px ) */
 @media (min-width:1537) and (max-width: 1600px) {

  .top-brand-sub2{
    display: flex;
    flex-direction: row;
    gap: 20px;

  }
  .brand-box {
    padding: 8px;
    width: 50%;
    height: 50px;
  }
  .top-brand-all{
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 30px;
    padding-left: 6%;
    padding-right: 6%;
   }
   .coupons-grid{
    display: grid;
    grid-template-columns: auto auto ;
    gap: 2%;
  }
   .brand-box-all{
    width: 100%;
   }
   .top-brand-container{
    padding-top: 1%;
  }
 
}


@media (min-width:1601px)
{

  .top-brand-container{
    padding-top: 1%;
  }
  .top-brand-sub2{
    display: flex;
    flex-direction: row;
    gap: 20px;

  }
  .brand-box {
    padding: 8px;
    width: 20%;
    height: 50%;
  }
  .top-brand-all{
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 30px;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 1%;
    padding-bottom: 1%;
   }

   .brand-box-all{
    width: 100%;
   }
   .coupons-grid{
    display: grid;
    grid-template-columns: auto auto ;
    gap: 2%;
  }

}
