.address-box {
    border: 2px solid #e2e8f0;
    padding: 1rem;
    margin-bottom: 1rem;
    /* background-color: #fff5f5; */
    position: relative;
  }

@media (min-width:360px) and (max-width:370px)

{
    .checkout-full-container{
        display: flex;
        flex-direction: column;
        padding: 5%;
    }

    .address-text-container {
        padding: 0%;
    }
    .checkout-payment-list {
        display: flex;
        flex-direction: column;
    }
  
        .add-btn {
            position: relative;
            left: -78%;
            margin-bottom: 40%;
        }
        
    .shipping-btn{
        position: relative;
        right: 42%;
        margin-bottom: 4%;

    }
    .order-summary-container{
        width:auto;
    }
    .shipping-container{
        display: grid;
        grid-template-columns: auto;
    }
    .payment-container{
        display: grid;
        grid-template-columns: auto;
    }
    .steps-list{
        width: 90%;
    }
    .payment-box {
        width: 83%;
        justify-content: flex-start;
        gap: 4%;
    }
    .shipping-box{
        width: 80%;
    }


    .address-box {
        width: 250px;
    }
    
      .address-box .address-row {
        display: flex;
        flex-direction: row;
        margin-bottom: 8px;
        font-size: small;
      }
    
      .address-box .address-label {
        font-weight: 400;
        margin-bottom: 4px;
      }
    
      .address-box .address-value {
        margin-bottom: 4px;
      }

      .add-icon{
        margin-right: 10%;
      }
}

@media (min-width:371px) and (max-width:375px)

{
    .checkout-full-container{
        display: flex;
        flex-direction: column;
        padding: 5%;
    }
    .address-text-container {
        padding: 0%;
    }
    .checkout-payment-list {
        display: flex;
        flex-direction: column;
    }
  
        .add-btn {
            position: relative;
            left: -188px;
            margin-bottom: 40%;
    
        }
        .shipping-btn {
            position: relative;
            right: 43%;
            margin-bottom: 9%;
        }
    .order-summary-container{
        width:auto;
    }
    .shipping-container{
        display: grid;
        grid-template-columns:auto;
    }
    .payment-container{
        display: grid;
        grid-template-columns: auto;
    }
    .steps-list{
        width: 90%;
    }
    .payment-box {
        width: 88%;
     
        justify-content: flex-start;
        gap: 7%;
    }
    .shipping-box{
        width: 83%;
    }
    .address-box {
        width: 255px;
    }
    .address-box .address-row {
        display: flex;
        flex-direction: row;
        margin-bottom: 8px;
        font-size: small;
      }
    
      .address-box .address-label {
        font-weight: 600;
        margin-bottom: 4px;
      }
    
      .address-box .address-value {
        margin-bottom: 4px;
      }

      .add-icon{
        margin-right: 10%;
      }
    
}

@media (min-width:376px) and (max-width:425px)

{
    .checkout-full-container{
        display: flex;
        flex-direction: column;
        padding: 5%;
    }
    .address-text-container {
        padding: 0%;
    }
    .checkout-payment-list {
        display: flex;
        flex-direction: column;
        padding-left: 0%;
    }
  
        .add-btn {
            position: relative;
        
            margin-bottom: 33%;
        }
        
    .shipping-btn{
        right: 34%;
        margin-bottom: 9%;

    }
    .order-summary-container{
        width:350px;
        /* padding-right: 20%;
        padding-left: 20%; */
    }
    .check-box{
        width: auto
  
    }
    .add-btn{
        left: -188px;
    }
    .shipping-container{
        display: grid;
        grid-template-columns: auto;
    }
    .payment-container{
        display: grid;
        grid-template-columns: auto;
    }
    .steps-list{
        width: 90%;
    }
    .payment-box {
        width: 90%;
     
        justify-content: flex-start;
        gap: 7%;
    }

    .shipping-box {
        width: 83%;
    }
    .address-box {
        width: 270px;
    }
    .address-box .address-row {
        display: flex;
        flex-direction: row;
        margin-bottom: 8px;
        font-size: small;
      }
    
      .address-box .address-label {
        font-weight: 600;
        margin-bottom: 4px;
      }
    
      .address-box .address-value {
        margin-bottom: 4px;
      }

      .add-icon{
        margin-right: 10%;
      }
    
}


@media (min-width:426px) and (max-width:625px)

{
    .checkout-full-container{
        display: flex;
        flex-direction: column;
        padding: 5%;
        justify-content: center;
    }
    .checkout-payment-list {
        display: flex;
        flex-direction: column;
    }
    .address-text-container {
        padding: 0%;
    }
  
        .add-btn {
            position: relative;
            right: -3%;
            margin-bottom: 40%;
        }
        
    .shipping-btn{
        position: relative;
        right: 20%;
            margin-bottom: 10%;

    }
    .address-text-container {
        padding: 0%;
    }
    .order-summary-container{
        width:auto;
    }
    .shipping-container{
        display: grid;
        grid-template-columns: auto;
    }
    .payment-container{
        display: grid;
        grid-template-columns: auto;
    }
    .steps-list{
        width: 90%;
    }
    .payment-box {
        width: 135%;
     
        justify-content: flex-start;
        gap: 7%;
    }
    .shipping-box{
        width: 120%;
    }
    .address-box {
        width: 310px;
    }
    .add-icon{
        margin-right: 20%;
      }
    
}


@media (min-width:626px) and (max-width:767px)

{
    .checkout-full-container{
        display: flex;
        flex-direction: column;
        padding: 5%;
        justify-content: center;
    }
    .address-text-container {
        padding: 0%;
    }
    .checkout-payment-list {
        display: flex;
        flex-direction: column;
    }
  
        .add-btn {
            position: relative;
            left: -6%;
            margin-bottom: 36%;
            width: 16%;
        }
        
    .shipping-btn{
        position: relative;

    

    }
    .order-summary-container{
        width:auto;
    }
    .shipping-container{
        display: grid;
        grid-template-columns: auto;
    }
    .payment-container{
        display: grid;
        grid-template-columns: auto;
    }
    .steps-list{
        width: 90%;
    }
    .payment-box {
        width: 135%;
     
        justify-content: flex-start;
        gap: 7%;
    }
    .shipping-box{
        width: 120%;
    }
    .address-box {
        width: 310px;
    }
    
}

@media (min-width:768px) and (max-width:965px)

{
    .checkout-full-container{
        display: flex;
        flex-direction: column;
        padding: 5%;
        justify-content: center;
    }
    .address-text-container {
        padding: 0%;
    }
  
        .add-btn {
            position: relative;
            left: -6%;
            margin-bottom: 36%;
            width: 16%;
        }
        
    .shipping-btn{
        position: relative;
        left: 7%;
            margin-bottom: 9%;

    }
    .order-summary-container{
        width:auto;
    }
    .shipping-container{
        display: grid;
        grid-template-columns: auto auto ;
    }
    .payment-container{
        display: grid;
        grid-template-columns: auto auto;
    }
    .steps-list{
        width: 90%;
    }
    .payment-box {
        width:auto;
     
        justify-content: flex-start;
        gap: 7%;
    }
    .shipping-box{
        width: auto;
    }
    .address-box {
        width: 320px;
    }
    
}

@media (min-width:966px) and (max-width:1024px)

{
    .checkout-full-container{
        display: flex;
        flex-direction: row;
        padding: 5%;
        justify-content: center;
    }
    .address-text-container {
        padding: 0%;
    }
  
            /* .add-btn {
                position: absolute;
                right: 33%;
                margin-bottom: 70%;
            } */
           
    .shipping-btn{
        position: relative;
        right: -39%;
        margin-bottom: 10%;

    }
    .order-summary-container{
        width:290px;
        /* padding-right: 20%;
        padding-left: 20%; */
    }
    .check-box{
        width: 570px
  
    }
    .add-btn{
        left: -45px;
    }
    .shipping-container{
        display: grid;
        grid-template-columns: auto auto ;
    }
    .payment-container{
        display: grid;
        grid-template-columns: auto auto;
    }
    .steps-list{
        width: 90%;
    }
    .payment-box {
        width:auto;
     
        justify-content: flex-start;
        gap: 7%;
    }
    .shipping-box{
        width: auto;
    }
    
}

@media (min-width:1025px) and (max-width:1137px)

{
    .checkout-full-container{
        display: flex;
        flex-direction: row;
        padding: 5%;
        justify-content: center;
    }
    .address-text-container {
        padding: 0%;
    }
 
  
            /* .add-btn {
                position: absolute;
                right: 33%;
                margin-bottom: 70%;
            } */
           
    .shipping-btn{
        right: 50%;


    }
    .order-summary-container{
        width:auto;
    }
    .shipping-container{
        display: grid;
        grid-template-columns: auto auto ;
    }
    .payment-container{
        display: grid;
        grid-template-columns: auto auto;
    }
    .steps-list{
        width: 90%;
    }
    .payment-box {
        width:auto;
     
        justify-content: flex-start;
        gap: 7%;
    }
    .shipping-box{
        width: auto;
    }
    .shipping-btn{
        position: relative;
        right: 8%;

    }
    
}

@media (min-width:1138px) and (max-width:1250px)

{
    .checkout-full-container{
        display: flex;
        flex-direction: row;
        padding: 5%;
        justify-content: center;
    }
    .address-text-container {
        padding: 0%;
    }
 
  
            /* .add-btn {
                position: absolute;
                right: 33%;
                margin-bottom: 70%;
            } */
           
    .shipping-btn{
        position: relative;
        right: 55%;
            margin-bottom: 100%;

    }
    .address-text-container {
        padding: 0%;
    }
    .order-summary-container{
        width:400px;
        /* padding-right: 20%;
        padding-left: 20%; */
    }
    .check-box{
        width: 600px
  
    }
    .add-btn{
        left: 500px;
    }
    .shipping-container{
        display: grid;
        grid-template-columns: auto auto ;
    }
    .payment-container{
        display: grid;
        grid-template-columns: auto auto;
    }
    .steps-list{
        width: 90%;
    }
    .payment-box {
        width:auto;
     
        justify-content: flex-start;
        gap: 7%;
    }
    .shipping-box{
        width: auto;
    }
    
}

@media (min-width:1251px) and (max-width:1280px)

{
    .checkout-full-container{
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 2%;
        padding: 3%;
        /* padding-right: 12%; */
       
    }
    .address-text-container {
        padding: 0%;
    }
  
            /* .add-btn {
                position: absolute;
                right: 33%;
                margin-bottom: 70%;
            } */
           
    .shipping-btn{
        position: relative;
        right: 10%;
        margin-bottom: 13%;

    }
    .order-summary-container{
        width:400px;
        /* padding-right: 20%;
        padding-left: 20%; */
    }
    .check-box{
        width: 700px
  
    }
    .add-btn{
        left: 500;
    }
    .shipping-container{
        display: grid;
        grid-template-columns: auto auto ;
    }
    .payment-container{
        display: grid;
        grid-template-columns: auto auto;
    }
    .steps-list{
        width: 90%;
    }
    .payment-box {
        width:auto;
     
        justify-content: flex-start;
        gap: 7%;
    }
    .shipping-box{
        width: auto;
    }
    .shipping-btn{
        position: relative;
        right: 8%;

    }
    
}

@media (min-width:1281px) and (max-width:1440px)

{
    .checkout-full-container{
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 1%;
        padding: 3%;
        /* padding-right: 12%; */
       
    }
    .address-text-container {
        padding: 0%;
    }
  
            /* .add-btn {
                position: absolute;
                right: 33%;
                margin-bottom: 70%;
            } */
           
    .shipping-btn{
        position: relative;
        right: -50%;
        margin-bottom: 13%;

    }
    .order-summary-container{
        width:500px;
        /* padding-right: 20%;
        padding-left: 20%; */
    }
    .check-box{
        width: 700px
  
    }
    .shipping-container{
        display: grid;
        grid-template-columns: auto auto ;
    }
    .payment-container{
        display: grid;
        grid-template-columns: auto auto;
    }
    .steps-list{
        width: 90%;
    }
    .payment-box {
        width:auto;
     
        justify-content: flex-start;
        gap: 7%;
    }
    .shipping-box{
        width: auto;
    }
    /* .shipping-btn{
        position: relative;
        right: 8%;

    } */
    
}

@media (min-width:1441px) and (max-width:1536px)

{
    .checkout-full-container{
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 2%;
        padding: 3%;
        /* padding-right: 12%; */
       
    }
    .address-text-container {
        padding: 0%;
    }
  
            /* .add-btn {
                position: absolute;
                right: 33%;
                margin-bottom: 70%;
            } */
           
    .shipping-btn{
        position: relative;
        right: -50%;
        margin-bottom: 13%;

    }
    .order-summary-container{
        width:500px;
        /* padding-right: 20%;
        padding-left: 20%; */
    }
    .check-box{
        width: 700px
  
    }
    .shipping-container{
        display: grid;
        grid-template-columns: auto auto ;
    }
    .payment-container{
        display: grid;
        grid-template-columns: auto auto;
    }
    .steps-list{
        width: 90%;
    }
    .payment-box {
        width:auto;
     
        justify-content: flex-start;
        gap: 7%;
    }
    .shipping-box{
        width: auto;
    }
    /* .shipping-btn{
        position: relative;
        right: 8%;

    } */
    
}

@media (min-width:1537px) and (max-width:1599px)

{
    .checkout-full-container{
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 2%;
        padding: 3%;
        /* padding-right: 12%; */
       
    }
    .address-text-container {
        padding: 0%;
    }
  
            /* .add-btn {
                position: absolute;
                right: 33%;
                margin-bottom: 70%;
            } */
           
    .shipping-btn{
        position: relative;
        right: 10%;
        margin-bottom: 13%;

    }
    .order-summary-container{
        width:150%;
        /* padding-right: 20%;
        padding-left: 20%; */
    }
    .check-box{
        width: 110%;
  
    }
    .shipping-container{
        display: grid;
        grid-template-columns: auto auto ;
    }
    .payment-container{
        display: grid;
        grid-template-columns: auto auto;
    }
    .steps-list{
        width: 90%;
    }
    .payment-box {
        width:auto;
     
        justify-content: flex-start;
        gap: 7%;
    }
    .shipping-box{
        width: auto;
    }
    .shipping-btn{
        position: relative;
        right: 8%;

    }
    
}



@media (min-width:1600px) {

    .checkout-full-container{
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 2%;
        padding: 3%;
        /* padding-right: 12%; */
        
       
    }
    .address-text-container {
        padding: 0%;
    }
    .order-summary-container{
        width:500px;
        /* padding-right: 20%;
        padding-left: 20%; */
    }
    .check-box{
        width: 800px
  
    }
    /* .add-btn{
        left: 500px;
    } */

    
            .add-btn {
                position: relative;
         
                /* margin-bottom: 8%; */
                margin-top: 1%;
                
        
            }
            .shipping-btn{
                position: relative;
                right: -57%;
                margin-bottom: 5%;

            }
}