@media (max-width: 370px) {
    .container {
      padding: 10px;
      max-width: 340px;
    }
    .text-xls {
      font-size: 1.2rem;
      word-wrap: break-word;
      overflow-wrap: break-word;
    }
  }
  
  @media (min-width: 371px) and (max-width: 375px) {
    .container {
      padding: 12px;
      max-width: 350px;
    }
    .text-xls {
      font-size: 1.3rem;
      word-wrap: break-word;
      overflow-wrap: break-word;
    }
  }
  
  @media (min-width: 376px) and (max-width: 400px) {
    .container {
      padding: 14px;
      max-width: 360px; 
    }
    .text-xls {
      font-size: 1.4rem;
      word-wrap: break-word;
      overflow-wrap: break-word;
    }
  }
  
  @media (min-width: 401px) and (max-width: 500px) {
    .container {
      padding: 12px;
      max-width: 400px; 
    }
    .text-xls {
      font-size: 1.5rem;
      word-wrap: break-word;
      overflow-wrap: break-word;
    }
  }
  
  @media (min-width: 501px) and (max-width: 600px) {
    .container {
      padding: 18px;
      max-width: 500px;
    }
    .text-xls{
      font-size: 1.6rem;
      word-wrap: break-word;
      overflow-wrap: break-word;
    }
  }
  
  @media (min-width: 601px) and (max-width: 767px) {
    .container {
      padding: 20px;
      max-width: 600px; 
    }
    .text-xls {
      font-size: 1.7rem;
      word-wrap: break-word;
      overflow-wrap: break-word;
    }
  }
  
  @media (min-width: 768px) and (max-width: 800px) {
    .container {
      padding: 24px;
      max-width: 700px;
    }
    .text-xls {
      font-size: 1.8rem;
      word-wrap: break-word;
      overflow-wrap: break-word;
    }
  }
  
  @media (min-width: 801px) and (max-width: 1000px) {
    .container {
      padding: 28px;
      max-width: 800px; 
    }
    .text-xls {
      font-size: 1.9rem;
      word-wrap: break-word;
      overflow-wrap: break-word;
    }
  }
  
  @media (min-width: 1001px) and (max-width: 1024px) {
    .container {
      padding: 12px;
      max-width: 1000px; 
    }
    .text-xls {
      font-size: 1.9rem;
      word-wrap: break-word;
      overflow-wrap: break-word;
    }
  }
  
  @media (min-width: 1025px) and (max-width: 1600px) {
    .container {
      padding: 32px;
      max-width: 1024px; 
    }
    .text-xls {
      font-size: 2rem;
      word-wrap: break-word;
      overflow-wrap: break-word;
    }
  }
  