
/* Header */

.cart-section, .Search-bar, .nav-bar-container{
    display: none;
}

@media (max-width: 425px) {
    .Search-bar {
        display: none;
    }
}



@media (min-width: 590px)
{
  .Search-bar {
    display: block;
}
  .Search-bar {
    margin-top: 10px;
    margin-right: 0%;
}
.Search-bar input {
  width: 220px;
  margin-left: 20%;
}
.Search-bar img {
  margin-top: 10px;
  margin-left: 20%;
}
}

@media (min-width: 690px)
{
  .Search-bar {
    display: block;
}
  .Search-bar {
    margin-top: 10px;
    margin-right: 0%;
}
.Search-bar input {
  width: 250px;
  margin-left: 20%;
}
.Search-bar img {
  margin-top: 10px;
  margin-left: 20%;
}
}

@media (min-width: 740px)
{
  .Search-bar {
    display: block;
}
  .Search-bar {
    margin-top: 10px;
    margin-right: 0%;
}
.Search-bar input {
  width: 290px;
  margin-left: 20%;
}
.Search-bar img {
  margin-top: 10px;
  margin-left: 20%;
}
}


@media (min-width: 769px) {
    .nav-bar-container,  .Search-bar {
        display: block;
    }
    
    .Search-bar {
        margin-top: 10px;
        margin-right: 0%;
    }
    
    .Search-bar input {
        width: 280px;
        margin-left: 20%;
    }
    
    .Search-bar img {
        margin-top: 10px;
        margin-left: 20%;
    }
    
    .nav-bar-container {
        display: flex;
        flex-direction: row;
    }
}

@media (min-width: 890px) {
  .nav-bar-container,  .Search-bar {
      display: block;
  }
  .cart-section{
    display: block;
    display: flex;
  }
  .nav-bar{
    gap: 0.5rem;
  }
  
  .Search-bar {
      margin-top: 10px;
      margin-right: 0%;
  }
  
  .Search-bar input {
      width: 280px;
      margin-left: 20%;
  }
  
  .Search-bar img {
      margin-top: 10px;
      margin-left: 20%;
  }
  
  .nav-bar-container {
      display: flex;
      flex-direction: row;
  }
}

@media (min-width: 1024px) {

    
    .Search-bar {
        margin-top: 10px;
        margin-right: 10%;
    }
    
    .Search-bar input {
        width: 350px;
    }
    .nav-bar{
      gap: 1.5rem;
    }
    .nav-bar-container {
      gap: 4%;
  }
    
    .Search-bar img {
        margin-top: 10px;
    }
    
    .cart-section {
        display: flex;
    }
    
}

@media (min-width:1600px) {

  .nav-bar-container{
    padding-left: 10%;
    padding-right: 9%;

    
  }
  


  /* Keyframes for showing and hiding items */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(20px);
  }
}

/* Classes for showing and hiding items */
.fade-in {
  animation: fadeIn 0.5s forwards;
}


    
}

/* Base styles for links with centered underline animation */
.hover-underline-animation {
    display: inline-block;
    position: relative;
    color: #ffffff;
    text-decoration: none;
    transition: color 0.2s ease-in-out;
    padding-bottom: 5px;
  }
  
  .hover-underline-animation.active {
    background-color: #EF4444; 
  }
  

  .hover-underline-animation::after {
    content: '';
    position: absolute;
    width: calc(100% - 20px);
    transform: scaleX(0);
    height: 4px; 
    bottom: 0;
    left: 50%;
    background-color: #ffffff; 
    transform-origin: center; 
    transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
  }
  
  /* Hover state for underline animation */
  .hover-underline-animation:hover::after {
    transform: scaleX(1); 
    width: 100%;
    left: 0;
  }

  .nav-link-underline {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px; 
    background-color: #ffff;
    transform-origin: bottom left;
    transform: scaleX(1);
    transition: transform 0.3s ease-in-out;
  }
  
  
  