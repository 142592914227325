
  *{
    margin: 0;
    padding: 0;
  }



  .favorite-button{
    border-color: #F03221;
  }

  .favorite-button:hover .favorite-icon path {
    fill: #F03221;
  }
  










/* App.css */
/* App.css */


.move-feedback {
  transform: translateX(0);
  transition: transform 0.5s ease; /* You can adjust the duration and easing as needed */
}

.hidden-feedback {
  transform: translateX(-100%);
}


  /* Summer sale */

.transition-container {
  display: flex;
  gap: 5px;
  transition: transform 2s ease;
}

.transition-item {
  flex-shrink: 0;
  width: calc(100% / 4); /* Adjust based on the number of items per page */
}


  @keyframes hoverAnimation {
    from {
      transform: scale(1);
      background-color: white;
      color: red;
    }
    to {
      transform: scale(1.05);
      background-color: red;
      color: white;
    }
  }
  
  .hover-animate:hover {
    animation: hoverAnimation 0.3s forwards;
  }
  

  /* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

img.rounded-full {
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}

.relative .overlay {
  position: absolute;
  top: 0;
  left: -13px;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease-in-out;
  opacity: 0;
}

.relative:hover .overlay {
  border-radius: 0;
  opacity: 1;
}
  
  .custom-scrollbar {
    max-height: 31rem; /* Adjust as needed */
    overflow-y: auto;
  }
  
  .custom-scrollbar::-webkit-scrollbar {
    width: 1px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  
  /* Filter CSS */
  .custom-slider .MuiSlider-thumb {
    @apply bg-primary-color;
  }

/* .custom-scrollbar::-webkit-scrollbar {
  width: 0.5rem; 
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: transparent; 
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: transparent; 
} */

  
  .custom-slider .MuiSlider-thumb:hover,
  .custom-slider .MuiSlider-thumb.Mui-focusVisible,
  .custom-slider .MuiSlider-thumb.Mui-active {
    @apply bg-primary-color shadow-none;
  }
  
  .custom-slider .MuiSlider-track {
    @apply bg-primary-color;
  }
  
  .custom-slider .MuiSlider-rail {
    @apply bg-light-color;
  }

  .image-hover-effect {
    transition: filter 0.3s ease; /* smooth transition effect */
}

.image-hover-effect:hover {
    filter: brightness(0) invert(1); /* change to white on hover */
}

/* Assuming you're using Tailwind CSS for utility classes */
/* Customizing scrollbar width */
::-webkit-scrollbar {
  width: 5px; /* Adjust as needed */
}

/* Styling the scrollbar track */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Light grey */
}

/* Styling the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background: #888; /* Dark grey */
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* Black */
}

.filter-link {
  cursor: pointer;
  padding: 5px;
  transition: color 0.3s;
}

.filter-link:hover {
  color: red;
}

.active{
  color: red;
 font-weight: 600
}




  .address-container {
    @apply grid gap-2;
  }
  .address-row {
    @apply flex items-center; 
  }
  .address-label {
    @apply font-semibold;
    min-width: 8rem; 
  }
  .address-value {
    @apply ml-4; 
  }

  .selected {
    background-color: #f56565; /* Example red color */
    color: white;
  }
  
  .hover\:bg-light-color:hover {
    background-color: #fed7d7;
  }
  
  .hover\:bg-light-color:hover {
    background-color: #feb2b2;
  }
  
  .tick {
    position: absolute;
    top: 2px;
    right: 2px;
    color: #f56565; /* Example red color */
    font-size: 1.5rem;
  }
  

/* Home Banner */


.drop-down {
  display: none;
}



@media (min-width:360px) and (max-width:370px) {

  .drop-down{
    width: 300%;
  }
  .banner-full-container
 {
  width: 100%;
 }
 .banner-container {
  padding: 0%;
  width: 100%;
  height: auto;
  margin-left: 0%;
}
.carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  color: #504a4a;
  border: 2px solid #7c7b7b; 
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(8, 7, 7, 0.2);
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
} 
.carousel-arrow.left {
  left: 7px;
}

.carousel-arrow.right {
  right: 7px;
}
  
}
@media (min-width:371px) and (max-width:375px) {

  .drop-down{
    width: 300%;
  }
  .banner-full-container
 {
  width: 100%;
 }
 .banner-container {
  padding: 0%;
  width: 100%;
  height: auto;
  margin-left: 0%;
}
.carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  color: #504a4a;
  border: 2px solid #7c7b7b; 
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(8, 7, 7, 0.2);
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
} 
.carousel-arrow.left {
  left: 7px;
}

.carousel-arrow.right {
  right: 7px;
}
  
}
@media (min-width:376px) and (max-width:425px) {
  .banner-full-container
 {
  width: 100%;
 }
 .banner-container {
  padding: 0%;
  width: 100%;
  height: auto;
  margin-left: 0%;
}
.banner-full-container {
  padding-top: 16%;
}
.carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  color: #504a4a;
  border: 2px solid #7c7b7b; 
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(8, 7, 7, 0.2);
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
} 
.carousel-arrow.left {
  left: 7px;
}

.carousel-arrow.right {
  right: 7px;
}
  
}
@media (min-width:426px) and (max-width:500px) {
  .banner-full-container
 {
  width: 100%;
 }
 .banner-full-container {
  padding-top: 7%;
}
.drop-down-nav-list{
  width: 287px;
}
.cat-button{
  width: 300px;
}
 .banner-container {
    padding: 0%;
    width: 100%;
    height: auto;
    margin-left: 0%;
}
.carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  color: #504a4a;
  border: 2px solid #7c7b7b; 
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(8, 7, 7, 0.2);
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
} 
.carousel-arrow.left {
  left: 7px;
}

.carousel-arrow.right {
  right: 7px;
}
  
}
@media (min-width:501px) and (max-width:767px) {
  .banner-full-container
 {
  width: 100%;
 }
 .banner-container {
    padding: 0%;
    width: 100%;
    height: auto;
    margin-left: 0%;
}
.drop-down-nav-list{
  width: 287px;
}
.cat-button{
  width: 300px;
}
.banner-full-container {
  padding-top: 7%;
}
.carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  color: #504a4a;
  border: 2px solid #7c7b7b; 
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(8, 7, 7, 0.2);
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
} 
.carousel-arrow.left {
  left: 7px;
}

.carousel-arrow.right {
  right: 7px;
}
  
}

@media (min-width: 768px) and (max-width:770px){
  .banner-full-container
  {
   width: 100%;
  }
  .banner-container {
    padding: 0%;
    width: 97%;
    height: auto;
    margin-left: 12px;
 }
 .drop-down-nav-list{
  width: 301px;
}
.cat-button{
  width: 320px;
}
 .carousel-arrow {
   position: absolute;
   top: 50%;
   transform: translateY(-50%);
   background-color: transparent;
   color: #504a4a;
   border: 2px solid #7c7b7b; 
   border-radius: 50%;
   width: 24px;
   height: 24px;
   display: flex;
   align-items: center;
   justify-content: center;
   cursor: pointer;
   box-shadow: 0 4px 8px rgba(8, 7, 7, 0.2);
   transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
 } 
 .carousel-arrow.left {
   left: 25px;
 }
 
 .carousel-arrow.right {
   right: 25px;
 }

 
}
@media (min-width: 1024px) {

  .nav-bar-drow{
    height: 427%;
  }
}


@media (min-width: 771px )and (max-width: 800px){
  .banner-full-container
  {
   width: 100%;
  }
  .banner-container {
    padding: 0%;
    width: 95%;
    height: auto;
    margin-left: 18px;
 }
 .carousel-arrow {
   position: absolute;
   top: 50%;
   transform: translateY(-50%);
   background-color: transparent;
   color: #504a4a;
   border: 2px solid #7c7b7b; 
   border-radius: 50%;
   width: 24px;
   height: 24px;
   display: flex;
   align-items: center;
   justify-content: center;
   cursor: pointer;
   box-shadow: 0 4px 8px rgba(8, 7, 7, 0.2);
   transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
 } 
 .carousel-arrow.left {
   left: 25px;
 }
 
 .carousel-arrow.right {
   right: 25px;
 }

 
}
@media (min-width: 801px )and (max-width: 900px){
  .banner-full-container
  {
   width: 100%;
  }
  .banner-container {
    padding: 0%;
    width: 98%;
    height: auto;
    margin-left: 10px;
 }
 .carousel-arrow {
   position: absolute;
   top: 50%;
   transform: translateY(-50%);
   background-color: transparent;
   color: #504a4a;
   border: 2px solid #7c7b7b; 
   border-radius: 50%;
   width: 24px;
   height: 24px;
   display: flex;
   align-items: center;
   justify-content: center;
   cursor: pointer;
   box-shadow: 0 4px 8px rgba(8, 7, 7, 0.2);
   transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
 } 
 .carousel-arrow.left {
   left: 25px;
 }
 
 .carousel-arrow.right {
   right: 25px;
 }

 
}
@media (min-width: 901px )and (max-width: 1000px){
  .banner-full-container
  {
    width: 60rem;
    margin-left: -6%;
  }
  .banner-container {
    padding: 0%;
    width: 100%;
    height: auto;

 }
 .carousel-arrow {
   position: absolute;
   top: 50%;
   transform: translateY(-50%);
   background-color: transparent;
   color: #504a4a;
   border: 2px solid #7c7b7b; 
   border-radius: 50%;
   width: 24px;
   height: 24px;
   display: flex;
   align-items: center;
   justify-content: center;
   cursor: pointer;
   box-shadow: 0 4px 8px rgba(8, 7, 7, 0.2);
   transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
 } 
 .carousel-arrow.left {
   left: 30px;
 }
 
 .carousel-arrow.right {
   right: 62px;
 }
  .drop-down-nav {
    display: block;
    /* height: 393px; */
    width: 300px;
    overflow-y: hidden;
  }
  .drop-down-view {
    width: 88%;
}

  .home-banner-dot {
      margin-left: 49px;
  }

}
@media (min-width: 1001px )and (max-width: 1024px) {
  .banner-full-container
  {
    width: 65rem;
    margin-left: -5%;
  }
  .banner-container {
    padding: 0%;
    width: 100%;
    height: auto;

 }
 .carousel-arrow {
   position: absolute;
   top: 50%;
   transform: translateY(-50%);
   background-color: transparent;
   color: #504a4a;
   border: 2px solid #7c7b7b; 
   border-radius: 50%;
   width: 24px;
   height: 24px;
   display: flex;
   align-items: center;
   justify-content: center;
   cursor: pointer;
   box-shadow: 0 4px 8px rgba(8, 7, 7, 0.2);
   transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
 } 
 .carousel-arrow.left {
   left: 30px;
 }
 
 .carousel-arrow.right {
   right: 30px;
 }
  .drop-down-nav {
    display: block;
    /* height: 393px; */
    width: 300px;
    overflow-y: hidden;
  }
  .drop-down-view {
    width: 88%;
}

  .home-banner-dot {
      margin-left: 49px;
  }

}

@media (min-width: 1025px) and (max-width: 1200px){
  .banner-full-container
  {
    width: 73rem;
    margin-left: -4%;
  }
  .banner-container {
    padding: 0%;
    width: 66rem;
    height: auto;

 }
 .carousel-arrow {
   position: absolute;
   top: 50%;
   transform: translateY(-50%);
   background-color: transparent;
   color: #504a4a;
   border: 2px solid #7c7b7b; 
   border-radius: 50%;
   width: 24px;
   height: 24px;
   display: flex;
   align-items: center;
   justify-content: center;
   cursor: pointer;
   box-shadow: 0 4px 8px rgba(8, 7, 7, 0.2);
   transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
 } 
 .carousel-arrow.left {
   left: 30px;
 }
 
 .carousel-arrow.right {
   right: 30px;
 }
  .drop-down-nav {
    display: block;
    /* height: 393px; */
    width: 300px;
    overflow-y: hidden;
  }
  .drop-down-view {
    width: 88%;
}

  .home-banner-dot {
      margin-left: 49px;
  }

}

@media (min-width: 1201px) and (max-width: 1280px) {



  .banner-full-container
  {
    width: 75rem;
    margin-left: 1px;
  }
  .banner-container {
    padding: 0%;
    width: 100%;
    height: auto;

 }
 .banner-image {
  width: 100%;
  height: auto;
}
 .carousel-arrow {
   position: absolute;
   top: 50%;
   transform: translateY(-50%);
   background-color: transparent;
   color: #504a4a;
   border: 2px solid #7c7b7b; 
   border-radius: 50%;
   width: 24px;
   height: 24px;
   display: flex;
   align-items: center;
   justify-content: center;
   cursor: pointer;
   box-shadow: 0 4px 8px rgba(8, 7, 7, 0.2);
   transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
 } 
 .carousel-arrow.left {
   left: 30px;
 }
 
 .carousel-arrow.right {
   right: 30px;
 }

  .custom-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb {
    width: 0%;
    background: #888;
    border-radius: 10px;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .drop-down {
    display: block;
    height: 393px;
    width: 302px;
    overflow-y: hidden;
  }
  .drop-down-nav {
    display: block;
    /* height: 393px; */
    width: 302px;
    overflow-y: hidden;
  }
  .home-banner-dot {
    margin-left: 86px;
  }

  .drop-down-view {
    width: 80%;
  }

  .nav-bar-drow {
    height: 427%;
  }
}
@media (min-width: 1281px) and (max-width: 1440px) {

  .banner-full-container
  {
    width: 85rem;
    margin-left: 3px;
  }
  .banner-container {
    padding: 0%;
    width: 100%;
   
 }
 .banner-image {
  width: 100%;
  height: auto;
}
 .carousel-arrow {
   position: absolute;
   top: 50%;
   transform: translateY(-50%);
   background-color: transparent;
   color: #504a4a;
   border: 2px solid #7c7b7b; 
   border-radius: 50%;
   width: 24px;
   height: 24px;
   display: flex;
   align-items: center;
   justify-content: center;
   cursor: pointer;
   box-shadow: 0 4px 8px rgba(8, 7, 7, 0.2);
   transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
 } 
 .carousel-arrow.left {
   left: 30px;
 }
 
 .carousel-arrow.right {
   right: 30px;
 }

  .custom-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb {
    width: 0%;
    background: #888;
    border-radius: 10px;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .drop-down {
    display: block;
    height: 467px;
    width: 300px;
    overflow-y: hidden;
  }
  .drop-down-nav {
    display: block;
    /* height: 467px; */
    width: 300px;
    overflow-y: hidden;
  }

  .drop-down-view {
    width: 80%;
  }

  .nav-bar-drow {
    height: 427%;
  }
}

@media (min-width: 1441px) and (max-width: 1500px) {

  .banner-full-container
  {
    width: 85rem;
    margin-left: 3px;
  }
  .banner-container {
    padding: 0%;
    width: 100%;
    

 }
 .banner-image {
  width: 100%;
  height: auto;
}
 .carousel-arrow {
   position: absolute;
   top: 50%;
   transform: translateY(-50%);
   background-color: transparent;
   color: #504a4a;
   border: 2px solid #7c7b7b; 
   border-radius: 50%;
   width: 24px;
   height: 24px;
   display: flex;
   align-items: center;
   justify-content: center;
   cursor: pointer;
   box-shadow: 0 4px 8px rgba(8, 7, 7, 0.2);
   transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
 } 
 .carousel-arrow.left {
   left: 30px;
 }
 
 .carousel-arrow.right {
   right: 30px;
 }

  .custom-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb {
    width: 0%;
    background: #888;
    border-radius: 10px;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .drop-down {
    display: block;
    height: 467px;
    width: 300px;
    overflow-y: hidden;
  }
  .drop-down-nav {
    display: block;
    /* height: 467px; */
    width: 300px;
    overflow-y: hidden;
  }

  .drop-down-view {
    width: 80%;
  }

  .nav-bar-drow {
    height: 427%;
  }
}

@media (min-width: 1501px) and (max-width: 1536px) {

  .home-banner-dot {
    margin-left: 90px;
  }
  .banner-full-container
  {
    width: 91rem;
    margin-left: 1px;
  }
  .banner-container {
    padding: 0%;
    width: 100%;

 }
 .carousel-arrow {
   position: absolute;
   top: 50%;
   transform: translateY(-50%);
   background-color: transparent;
   color: #504a4a;
   border: 2px solid #7c7b7b; 
   border-radius: 50%;
   width: 24px;
   height: 24px;
   display: flex;
   align-items: center;
   justify-content: center;
   cursor: pointer;
   box-shadow: 0 4px 8px rgba(8, 7, 7, 0.2);
   transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
 } 
 .carousel-arrow.left {
   left: 30px;
 }
 
 .carousel-arrow.right {
   right: 30px;
 }

  .custom-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb {
    width: 0%;
    background: #888;
    border-radius: 10px;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .drop-down {
    display: block;
    height: 497px;
    width: 295px;
    overflow-y: hidden;
  }
  .drop-down-nav {
    display: block;
    /* height: 497px; */
    width: 300px;
    overflow-y: hidden;
  }

  .drop-down-view {
    width: 80%;
  }

  .nav-bar-drow {
    height: 427%;
  }
}



@media (min-width:1600px) {
  .banner-full-container
  {
    width: 100rem;
    margin-left: 116px;
  }
  .banner-container {
    padding: 0%;
    width: 100%;
 }
 .carousel-arrow {
   position: absolute;
   top: 50%;
   transform: translateY(-50%);
   background-color: transparent;
   color: #504a4a;
   border: 2px solid #7c7b7b; 
   border-radius: 50%;
   width: 24px;
   height: 24px;
   display: flex;
   align-items: center;
   justify-content: center;
   cursor: pointer;
   box-shadow: 0 4px 8px rgba(8, 7, 7, 0.2);
   transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
 } 
 .carousel-arrow.left {
   left: 30px;
 }
 
 .carousel-arrow.right {
   right: 30px;
 }


  .custom-scrollbar::-webkit-scrollbar {
    display: none; /* Hide all scrollbar components for WebKit browsers */
  }

  .custom-scrollbar::-webkit-scrollbar {
    width: 1px;
  }
  .drop-down 
    {
    display: block; 
    height: 584px;
    width: 296px;
  }
  .drop-down-nav 
  {
  display: block; 
  /* height: 584px; */
  width: 299px;
}
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .drop-down-view {
    width: 88%;
}
  
  .nav-bar-drow{
    height: 427%;
  }
}


/* Home Banner End */



/* .sectiontemp {
  display: none;
  margin-left: 12px;
} */

/* 
@media (min-width:360px) and (max-width:370)
{
  .home-banner-dot-btn {
    width: 0.5rem; 
    height: 0.5rem; 
    padding-bottom: 0.75rem;
  }

}


@media (min-width: 760px) and (max-width: 1023px) {
  .sectiontemp{
    display: block;
    background-color: white;
  }
  .sectiontemp {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 10px;
    margin-left: 10px;
  }

  .sectiontemp > div {
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: white;
    border-radius: 8px;
    padding: 10px;
  }
} */

/* @media (min-width: 1024px) and (max-width: 1300px)  {
  .sectiontemp{
    display: block;
    background-color: white;
  }
  .sectiontemp {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-left: 1%;
    margin-right: 2%;
  }

  .sectiontemp > div {
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: white;
    border-radius: 8px;
    padding: 10px;
  }
  .sectiontemp{
    display: none;
    background-color: white;
  }
  .sectiontemp {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 10px;
    margin-left: 10px;
  }
} */
/* @media (min-width:1440px) {
  .sectiontemp {
    margin-left: 3%;
    margin-right: 3%;
  }
} */

/* @media (min-width: 1020px) {
  .sectiontemp {
    display: grid;
    grid-template-columns: 4fr 1fr;
    gap: 10px;
    margin-left: 10px;
  }
  .sectiontemp{
    display: block;
    background-color: white;
  }
  .section {
      margin-left: -6px;
      margin-right: 12px;
  }
} */

/* @media (max-width:1440px) {

  .section{
    margin-left: 36px;
    margin-right: 12px
  }
  
} */



/* @media (min-width:1600px) {
  .sectiontemp {
    display: flex;

    gap: 10px;
    margin-left: 10px;
  }
  .sectiontemp{
    display: block;
    background-color: white;
  }

  section{
    margin-left: 0px;
    margin-right: 12px;
  }
  .sectiontemp {
 margin-left: 180px;
 margin-right:180px;
  }
  .drop-down 
  {
  display: block; 
  height: 584px;
  width: 298px;
}

.banner-image {
  width: 100%;
  height: auto;
}
} */







.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}





  



/* ********************************************************************** */



/* .carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  color: #504a4a;
  border: 2px solid #7c7b7b; 
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(8, 7, 7, 0.2);
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
} */

.carousel-arrow:hover {
  background-color: #fff; /* Show white background on hover */
  color: #000; /* Change arrow color to black on hover */
  border-color: #000; /* Border color changes to black on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

/* .carousel-arrow.left {
  left: 16px;
}

.carousel-arrow.right {
  right: 78px;
} */
