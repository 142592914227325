
/* Summer sale */

.summer-container{
    display: flex;
  }
  
  @media (min-width: 320px) and (max-width: 374px)  {
  
    .summer-container{
      flex-direction: column;
      justify-content: center;
      /* gap: 20%; */
      padding: 6%;
    }
    .summer-product{
   padding-top: 15%;
     
    }
    .summer-product-box{
      width: 70%;
    }
    .summer-product-box img{
      width: 100%;
      height: auto;
    }
  
  }
  
  /* Small devices (phones, 375px to 479px) */
  @media (min-width: 375px) and (max-width: 479px) {
  
    .summer-container{
      flex-direction: column;
      justify-content: center;
      /* gap: 20%; */
      padding: 4%;
    }
    .summer-product{
   padding-top: 15%;
     
    }
    .summer-product-box{
      width: 70%;
      height: auto;
    }
    .summer-product-box img{
      width: 100%;
      height: auto;
    }
  
  }
  
  /* Small to medium devices (phones, 480px to 599px) */
  @media (min-width: 480px) and (max-width: 599px) {
    
    .summer-container{
      flex-direction: column;
      justify-content: center;
      gap: 20%;
    }
    .summer-product{
   padding-top: 15%;
     
    }
    .summer-product-box{
      width: 70%;
      height: auto;
    }
    .summer-product-box img{
      width: 100%;
      height: auto;
    }
  
  }
  
  /* Medium devices (tablets, 600px to 767px) */
  @media (min-width: 600px) and (max-width: 767px) {
  
    .summer-container{
      flex-direction: column;
      justify-content: center;
      gap: 20%;
    }
    .summer-product{
   padding-top: 15%;
     
    }
    .summer-product-box{
      width: 70%;
      height: auto;
    }
    .summer-product-box img{
      width: 100%;
      height: auto;
    }
  
  }
  
  /* Medium to large devices (tablets, 768px to 991px) */
  @media (min-width: 768px) and (max-width: 991px) {
  
    .summer-container{
      flex-direction: flex;
      justify-content: end;
      gap: 4%;
      /* padding-right: 4%; */
      padding: 3%;

    }
    .summer-product-box{
      width: 70%;
      height: auto;
    }
    
  }
  
  /* Large devices (desktops, 992px to 1000px) */
  @media (min-width: 992px) and (max-width: 1000px) {
  
    .summer-container{
      flex-direction: flex;
      justify-content: center;
     gap: 20px;
    }
  }
  
  /* Extra large devices (large desktops, 1001px to 1024px) */
  @media (min-width: 1001px) and (max-width: 1024px) {
    .summer-container-main{
      padding-left: 1%;
      padding-right: 5%;
    }
  
    .summer-container{
      flex-direction: flex;
      justify-content: center;
     gap: 20px;
    }
  
  }

  @media (min-width: 1025px) and (max-width: 1200px) {
  
    .summer-container{
      flex-direction: flex;
      justify-content: center;
     gap: 20px;
    }
  
  }
  @media (min-width: 1201px) and (max-width: 1280px) {
  
    .summer-container{
      flex-direction: flex;
      justify-content: center;
     gap: 20px;
     padding: 2%;
    }
  
  }
  
  /* Extra extra large devices (larger desktops, 1025px to 1440px) */
  @media (min-width: 1281px) and (max-width: 1440px) {
    .summer-container-main{
      padding-left: 1%;
      padding-right: 5%;
    }
  
    .summer-container{
      flex-direction: flex;
     padding-left: 5%;
     padding-right: 5%;
 }
    
  
  }
  @media (min-width: 1441px) and (max-width: 1500px) {
    .summer-container-main{
      padding-left: 1%;
      padding-right: 5%;
    }
  
    .summer-container{
      flex-direction: flex;
     padding-left: 5%;
     padding-right: 5%;
     gap: 2%;
 }
    
  
  }

  @media (min-width: 1501px) and (max-width: 1536px) {
    .summer-container-main{
      padding-left: 1%;
      padding-right: 5%;
    }
  
    .summer-container{
      flex-direction: flex;
     padding-left: 5%;
     padding-right: 5%;
     gap: 2%;
 }
    
  
  }


  @media (min-width: 1537px) and (max-width:1599px) {
    .summer-container-main{
      padding-left: 0%;
      padding-right: 2%;
    }
  
    .summer-container{
      flex-direction: flex;
      justify-content: center;
     gap: 70px;
    }
   
  }

  
  /* Ultra wide devices (1442px to 1600px ) */
  @media (min-width: 1600px) {
  
    .summer-container{
     
      flex-direction: flex;
      justify-content: space-between;
      /* gap: 118px; */
      margin-left: 9%;
      margin-right: 9%;
    }
   
  }

  /* @media (min-width:1600px)
  {
    .summer-container-main{
      padding-left:  7%;
      padding-right: 10%;
    }
  } */



  
  
  