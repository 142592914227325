@media (min-width:360px) and (max-width:370px){
   .login-container{
      width: 75%;
      
   }
   .react-tel-input .form-control{
      width: 100% !important;
      }
.send-otp{
   width: 36%;
   margin-left: 67% !important;
}
.otp-box{
   padding-top: 2%;
}

.otp-box input{
   width: 6%;
   height: 3%;
}
}

@media (min-width:371px) and (max-width:390px){
    .login-container{
       width: 82%;
       
    }
    .react-tel-input .form-control{
      width: 100% !important;
      }
.send-otp{
   width: 36%;
   margin-left: 67% !important;
}
.otp-box{
   padding-top: 2%;
}

.otp-box input{
   width: 6%;
   height: 3%;
}
}

@media (min-width:391px) and (max-width:425px){
   .login-container{
      width: 80%;
      
   }
.react-tel-input .form-control{
width: 100% !important;
}
.otp-box{
   padding-top: 2%;
}

.otp-box input{
   width: 7%;
   height: 7%;
}
.send-otp{
   width: 36%;
   margin-left: 67% !important;
}

}

@media (min-width:426px) and (max-width:785px){
   .login-container{
      width: 80%;
      
   }
.react-tel-input .form-control{
width: 100% !important;
}
.otp-box{
   padding-top: 2%;
}

.otp-box input{
   width: 7%;
   height: 7%;
}
.send-otp{
   width: 36%;
   margin-left: 67% !important;
}

}

@media (min-width:786px){
   .login-container{
      width: 90%;
      
   }
.react-tel-input .form-control{
width: 240px !important;
}
.otp-box{
   padding-top: 2%;
}
.send-otp{
   width: 30%;
   margin-left: 67% !important;
}

.otp-box input{
   width: 9%;
   height: 11%;
}

}