

/* Phone menu */

.phone_menu_button,
.phone_menu {
  display: none;
  bottom: 0;
}

@media (max-width: 768px) {
  .phone_menu_button {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background-color: white;
    width: 100%;
  }
  
  .phone_menu_button img {
    width: 20px;
    height: 20px;
  }
  
  .phone_menu_button span {
    font-size: small;
  }

  .phone_menu {
    display: block;
    position: fixed;
    left: -350px; 
    top: 0;
    width: 350px;
    height: 100%;
    background-color: white;
    transition: left 0.3s ease-in-out;
    z-index: 1000;
  }

  .phone_menu.open {
    left: 0;
  }
  .cancel-btn{
    margin-left: 24px;
  }
  .custom-scrollbar-menu {
    max-height: 31rem;
    overflow-y: auto;
}
.custom-scrollbar-menu::-webkit-scrollbar {
  width: 0px;
}
}

@media (max-width: 425px) {
  .phone_menu_button {
    padding: 8px;
  }
  
  .phone_menu_button img {
    width: 18px;
    height: 18px;
  }
  
  .phone_menu_button span {
    font-size: smaller;
  }
  .cancel-btn{
        margin-left: -63px;
  }
  .phone_menu {
    width: 250px; 
  }
  .custom-scrollbar-menu {
    max-height: 31rem;
    overflow-y: auto;
}
.custom-scrollbar-menu::-webkit-scrollbar {
  width: 0px;
}
}


@media (min-width:360px) and (max-width:375px)
{
  .tablet-category-list{
    width: 225px;
  }
}

@media (min-width:425px) and (max-width:500px)
{
  .tablet-category-list{
    width: 225px
  }
}

@media (min-width:501px) and (max-width:767px)
{
  .tablet-category-list{
    width: 300px
  }
}

@media (min-width:768px) and (max-width:800px)
{
  .tablet-category-list{
    width: 300px
  }
}