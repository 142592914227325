@keyframes slide-fade-up {
    0% {
      transform: translateY(50px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .animate-slide-fade {
    opacity: 0;
    animation: slide-fade-up 1.0s ease-out forwards;
  }
  



.About-container{
    padding-top: 5%;
    padding-bottom: 5%;

    padding-left: 10%;
    padding-right: 10%;
}

.About-Subcontainer{
    padding-top: 5%;
    padding-bottom: 5%;

    padding-left: 10%;
    padding-right: 10%;
}


@media (min-width:1024px) and (max-width:1279px) {
    .About-container{
        padding-top: 5%;
        padding-bottom: 5%;
    
        padding-left: 4%;
        padding-right:4%;
    }
    
    .About-Subcontainer{
        padding-top: 5%;
        padding-bottom: 5%;
     
        padding-left: 4%;
        padding-right:4%;
    }
}

@media (min-width:656px) and (max-width:786px) {
    .About-container{
        padding-top: 5%;
        padding-bottom: 5%;
    
        padding-left: 4%;
        padding-right:4%;
    }
    
    .About-Subcontainer{
        padding-top: 5%;
        padding-bottom: 5%;
     
        padding-left: 4%;
        padding-right:4%;
    }
    .back-ground-img{
        height: 300px;
    }
}



