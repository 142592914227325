
/* Today Deal */

/* .today-flex,
.todaydeals
{

  flex-direction: row;
  justify-content: space-between;
  width:auto;
  gap:30px

} */

/* Extra small devices (phones, 320px to 374px) */
/* @media (min-width: 360px) and (max-width: 374px) {
  .today-flex {
    flex-direction: column;
    width: auto;
    
  }

  .todaydeals {
    flex-direction: column;
    width: auto;
    text-align: left;
  }

  .today-flex-img {
    width: 82%;
    height: auto;
    margin: 0 auto 15px;
    margin-left: 13%;
  }

  .today-product {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  .today-product-box {
    width: 93%;
    height: auto;
    font-size: small;
    margin-left: 0px;
  }

  .today-product-box img {
    width: 100%;
    height: auto;
  }


} */



/* Small devices (phones, 375px to 479px) */
@media (min-width: 375px) and (max-width: 424px) {

  .today-flex{
    flex-direction: column;
    width: auto ;

  }
  .todaydeals {
    flex-direction: column;
    width: auto;

    
  }

  .todaydeals {
    text-align: left;
  }

  .today-flex-img {
    width: 78%;
    height: auto;
  }

  .today-product {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;


  }

  .today-product-box {
    width: 89%;
    height: auto;
    font-size: small;
  }

  .today-product-box img {
    width: 100%; 
    height: auto; 
  }

}

@media (min-width: 425px) and (max-width: 479px) {

  .today-flex{
    flex-direction: column;
    width: auto ;
    margin-left: -2%;
    margin-right: 6%;

  }
  .todaydeals {
    flex-direction: column;
    width: auto;
    padding-left: 10%;
    padding-right: 10%;
    
  }

  .todaydeals {
    text-align: left;
  }



  .today-product {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;


  }

  .today-product-box {
    width: 89%;
    height: auto;
    font-size: small;
  }

  .today-product-box img {
    width: 100%; 
    height: auto; 
  }

}
/* Small to medium devices (phones, 480px to 599px) */
@media (min-width: 480px) and (max-width: 599px) {

  .today-flex,
  .todaydeals {
    flex-direction: column;
    width: auto;
    padding-right: 5%;
    
  }

  .todaydeals {
    text-align: left;
  }

  .today-flex-img {
    width: 90%;
    height: auto;
    margin: 0 auto 15px; /* Centering image and adding bottom margin */
    margin-left: 30px;
  }

  .today-product {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
      gap: 10px;

  }

  .today-product-box {
    width: 89%;
    height: auto;
    font-size: small;
  }

  .today-product-box img {
    width: 100%; 
    height: auto; 
  }

}

/* Medium devices (tablets, 600px to 767px) */
@media (min-width: 600px) and (max-width: 767px) {

  .today-flex,
  .todaydeals {
    flex-direction: column;
    width: auto;
    padding-right: 5%;
    
  }

  .todaydeals {
    text-align: left;
  }

  .today-flex-img {
    width: 90%;
    height: auto;
    margin: 0 auto 15px; /* Centering image and adding bottom margin */
    margin-left: 30px;
  }

  .today-product {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
      gap: 10px;

  }
  

  .today-product-box {
    width: 89%;
    height: auto;
    font-size: small;
  }

  .today-product-box img {
    width: 100%; 
    height: auto; 
  }

}

/* Medium to large devices (tablets, 768px to 991px) */
@media (min-width: 768px) and (max-width: 991px) {
  .today-flex,
  .todaydeals {
    flex-direction: row;
    width: auto;
    padding-right: 2%;
    /* position: relative; */
    
  }

  .todaydeals {
    text-align: left;
  }

  .today-flex-img {
    width: 76%;
    height: 800px;
  }

  .today-product {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
      gap: 5px;

  }
  .premium-product {
    height: 800px; 
    overflow-y: scroll;
    scrollbar-width: none; 
    -ms-overflow-style: none;  
    width: 900px;
  }
  
  .premium-product::-webkit-scrollbar {
    width: 0;  
    display: none;
  }
  
  

  .today-product-box {
    width: 100%;
    height: auto;
   
  }

  .today-product-box img {
    width: 100%; 
    height: auto; 
  }
}

/* Large devices (desktops, 992px to 1199px) */
@media (min-width: 992px) and (max-width: 1000px) {
  .today-flex,
  .todaydeals {
    flex-direction: row;
    width: auto;
    padding-right: 7%;
    
  }

  .todaydeals {
    text-align: left;
  }

  .today-flex-img {
    width: 100%;
    height: auto;
    margin: 0 auto 15px; /* Centering image and adding bottom margin */
    margin-left: 30px;
  }

  .today-product {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
      gap: 10px;

  }

  .today-product-box {
    width: 100%;
    height: auto;
   
  }

  .today-product-box img {
    width: 100%; 
    height: auto; 
  }
}

/* Extra large devices (large desktops, 1200px to 1439px) */
@media (min-width: 1001px) and (max-width: 1024px) {
  .today-product{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
  .today-product-box{
    width: 100%;

  }
  .premium-product {
    height: 700px; 
    overflow-y: scroll;
    scrollbar-width: none; 
    -ms-overflow-style: none;  
  }
  
  .premium-product::-webkit-scrollbar {
    width: 0;  
    display: none;
  }
  .today-product{
    margin-right: 30px;
  }
  .today-flex {
    margin-left: 10px;
    padding-left: 3%;
  
  }





}

/* Extra extra large devices (larger desktops, 1440px to 1599px) */
@media (min-width: 1025px) and (max-width: 1200px) {
  .today-product{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
  .today-product-box{
    width: 100%;
  }
  .today-product{
    margin-right: 30px;
  }
  .today-flex {
    margin-left: 10px;
  
  }

}
@media (min-width: 1201px) and (max-width: 1440px) {
  

 
  .today-flex,
  .todaydeals {
    flex-direction: row;
    width: auto;
    padding-left: -2%;
    padding-right: 2%;
    
  }
  .today-full-container{
    padding-left:  8%;
    padding-right: 10%;
  }
  
  .today-product {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
      gap: 15px;
  }

  .today-product-box {
    width: 90%;
    height: auto;
  }

  .today-flex {
    margin-left: 10px; /* Ensure consistent margin on the left */
  }
  .today-product-box img{
    width: 85%;
    height: auto;
    margin: 0 auto 15px; 
    margin-left: 10px;
  }
}


/* Ultra wide devices (1600px and up) */
@media (min-width: 1441px) and  (max-width: 1600px) {

  .today-product {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
    margin-right: 30px; 
  }

  .today-product-box {
    width: 100%;
  }

  .today-flex {
    margin-left: 10px; /* Ensure consistent margin on the left */
  }

}
@media (min-width: 1600px) {

  .today-full-container{
    padding-left:  8%;
    padding-right: 10%;
  }

  .today-product {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    margin-right: 30px; 
  }

  .today-product-box {
    width: 100%;
  }

  .today-flex {
    margin-left: 10px; /* Ensure consistent margin on the left */
  }
  .today-product-box img{
    width: 77%;
    height: auto; 
    margin-right: 0%;
  }


}





/* Today Deal End */
